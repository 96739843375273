import React from "react";
import "./UnderInput.css";
const UnderInput = ({ close, children }) => {
  return (
    <>
      <div onBlur={close} className={"underInput"}>
        {children}
      </div>
    </>
  );
};
export default UnderInput;
