import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import useGetGdpr from "../../../hooks/get-gdpr";
import { Modal } from "react-rainbow-components";
import PostGpdr from "../../function/post-gdpr";
import { useIntl, FormattedMessage } from "react-intl";
import InformativaDisplayer from "./InformativaDisplayer";
const Gdpr = (props) => {
  const intl = useIntl();
  const [er, setEr] = useState(false);
  const [co, setCo] = useState(false);
  const [el, setEl] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const openDocumentHandler = () => {
    setIsDocument((old) => !old);
  };
  const gdprs = useGetGdpr();
  const erHandler = () => {
    if (er) {
      setEr(false);
      setCo(false);
      setEl(false);
    } else {
      setEr(true);
    }
    
  };
  const coHandler = () => {
    if (co) {
      setCo(false);
    } else {
      setCo(true);
      setEr(true);
    }
  };
  const elHandler = (e) => {
    e.preventDefault();
    if (el) {
      setEl(false);
    } else {
      setEl(true);
      setCo(true);
      setEl(!el);
      setEr(true);
    }
    let consents = [];
              if (el || gdprs.info) {
                consents.push({
                  name: "info",
                  text: "Inviare messaggi promozionali di terze parti",
                  slug: "info",
                });
              }
              if (er || gdprs.privacy) {
                consents.push({
                  name: "privacy",
                  text: "Erogare il servizio (obbligatorio)",
                  slug: "privacy",
                });
              }
              if (co || gdprs.newsletter) {
                consents.push({
                  name: "newsletter",
                  text: "Comunicare le novità Best Golf",
                  slug: "newsletter",
                });
              }
              if (props.returnConsents) {
                props.returnConsents(consents);
              }
  };
  const openHandler = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  if (props.small) {
    return (
      <>
        <div className="d-flex">
          <div className="row">
            <div className="col-2">
              {" "}
              <Checkbox
                style={{ height: "10px" }}
                checked={el}
                onChange={elHandler}
              />
            </div>
            <div className="col-10 my-2" style={{ fontSize: "12px" }}>
              {" "}
              {intl.formatMessage({id:"Fornisco il consenso al trattamento dei miei dati personali."})}
              <text
                style={{ color: props.color || "white", fontWeight: 700 }}
              ></text>
              .
            </div>

            <Modal isOpen={isDocument} onRequestClose={openDocumentHandler}>
              <InformativaDisplayer nome={"newsletter"} />

               {/*
               NB: utilizzare il div con classe text-privacy per inglobare il contenuto, ho notato che in particolare modo le privacy della newsletter ha un testo introduttivo che onon è presente in questo che mi avete passato quindi non so bisongneraà verificare

              <div className="text-privacy">
                <h3><b>Perché trattiamo i tuoi dati?</b></h3>
                <p>Best Golf raccoglie i tuoi dati per darti la possibilità di prenotare e acquistare Green fee, gare e vacanze Golf da te scelti, e per fornirti servizi collegati al mondo del Golf.</p>
                <p>Per ogni finalità, è richiesto uno specifico consenso al trattamento:</p>
                <div className="d-flex align-items-center ">
                  <div>
                    <i className="far fa-envelope fa-2x color-primary" aria-hidden="true"></i>
                  </div>
                  <div className="pl-3">
                    <h5><strong>Erogare il servizio</strong></h5>
                  </div>
                </div>

                <p>I dati sono utilizzati per:</p>
                <ol>
                  <li>Creare un account necessario a conoscere chi prenota/acquista i servizi; </li>
                  <li>Fornire gli estremi della prenotazione/acquisto al Campo da Golf o alla struttura turistica; </li>
                  <li>Inviare le email di conferma della prenotazione/acquisto; </li>
                  <li>Fornire servizi personalizzati disponibili nell’Area Riservata; </li>
                  <li>Fornire supporto operativo e commerciale; </li>
                  <li>Inviare email contenenti comunicazioni di servizio.</li>
                </ol>
                <div className="d-flex align-items-center ">
                  <div>
                    <i className="far fa-bell fa-2x color-primary" aria-hidden="true"></i>
                  </div>
                  <div className="pl-3">
                    <h5><strong>Comunicare le novità Best Golf</strong></h5>
                  </div>
                </div>

                <p>I dati sono utilizzati per attività di comunicazione diretta, in particolare per:</p>
                <ol>
                  <li>Inviare email che contengono informazioni sulle attività di Best Golf; </li>
                  <li>Inviare email che contengono informazioni dei partner di Best Golf su eventi, promozioni e offerte disponibili sulla piattaforma Best Golf.</li>
                </ol>

                <div className="d-flex align-items-center ">
                  <div>
                    <i className="fas fa-bullhorn fa-2x color-primary" aria-hidden="true"></i>
                  </div>
                  <div className="pl-3">
                    <h5><strong>Inviare messaggi promozionali di terze parti</strong></h5>
                  </div>
                </div>
                <p>I dati sono utilizzati per attività di profilazione e marketing, in particolare per: </p>
                <ol>
                  <li>Elaborare i dati in base al comportamento d’acquisto per creare liste profilate; </li>
                  <li>Utilizzare le liste profilate per finalità di co-marketing effettuato in collaborazione con organizzazioni interessate.</li>
                </ol>
                <p><em>Best Golf raccoglie e tratta i tuoi dati in qualità di Titolare del Trattamento, conformemente a quanto previsto dal Regolamento (UE) 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali. Per avere maggiori dettagli sul trattamento dati e conoscere meglio i tuoi diritti,
                  leggi la nostra “Informativa estesa sulla Protezione dei Dati personali”.</em></p>
                <p>Andando avanti sul processo di prenotazione/acquisto, dichiari di aver preso visione dei seguenti documenti e di accettarne i termini e le condizioni contenute:</p>
                <p><a href="https://italybestgolf.com/doc_bg/2022-03-Informativa-estesa.pdf" target="_blank"><strong className="color-primary">Informativa sulla Protezione dei Dati personali</strong></a></p>
                <p><a href="https://italybestgolf.com/doc_bg/2022-03-Termini-condizioni.pdf" target="_blank"><strong className="color-primary">Termini e condizioni di prenotazione e vendita online</strong></a></p>
              </div>



               */}





            </Modal>
            <div className="col-12 my-2">
              {el && props.children}{" "}
              {el && (
                <div>
                  {intl.formatMessage({id:"Dichiaro di aver letto l'informativa Newsletter sulla protezione dei dati disponibile"})}
                  {" "}
					  <text  style={{fontWeight:700,cursor:"pointer"}}onClick={openDocumentHandler}>{intl.formatMessage({id:"qui"})}</text>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else if (!props.small && !gdprs.privacy) {
    return (
      <>
        <Modal isOpen={isDocument} onRequestClose={openDocumentHandler}>
          <InformativaDisplayer nome={"iscrizione"} />

          {/*
               NB: utilizzare il div con classe text-privacy per inglobare il contenuto, ho notato che in particolare modo le privacy della newsletter ha un testo introduttivo che onon è presente in questo che mi avete passato quindi non so bisongneraà verificare

              <div className="text-privacy">
                <h3><b>Perché trattiamo i tuoi dati?</b></h3>
                <p>Best Golf raccoglie i tuoi dati per darti la possibilità di prenotare e acquistare Green fee, gare e vacanze Golf da te scelti, e per fornirti servizi collegati al mondo del Golf.</p>
                <p>Per ogni finalità, è richiesto uno specifico consenso al trattamento:</p>
                <div className="d-flex align-items-center ">
                  <div>
                    <i className="far fa-envelope fa-2x color-primary" aria-hidden="true"></i>
                  </div>
                  <div className="pl-3">
                    <h5><strong>Erogare il servizio</strong></h5>
                  </div>
                </div>

                <p>I dati sono utilizzati per:</p>
                <ol>
                  <li>Creare un account necessario a conoscere chi prenota/acquista i servizi; </li>
                  <li>Fornire gli estremi della prenotazione/acquisto al Campo da Golf o alla struttura turistica; </li>
                  <li>Inviare le email di conferma della prenotazione/acquisto; </li>
                  <li>Fornire servizi personalizzati disponibili nell’Area Riservata; </li>
                  <li>Fornire supporto operativo e commerciale; </li>
                  <li>Inviare email contenenti comunicazioni di servizio.</li>
                </ol>
                <div className="d-flex align-items-center ">
                  <div>
                    <i className="far fa-bell fa-2x color-primary" aria-hidden="true"></i>
                  </div>
                  <div className="pl-3">
                    <h5><strong>Comunicare le novità Best Golf</strong></h5>
                  </div>
                </div>

                <p>I dati sono utilizzati per attività di comunicazione diretta, in particolare per:</p>
                <ol>
                  <li>Inviare email che contengono informazioni sulle attività di Best Golf; </li>
                  <li>Inviare email che contengono informazioni dei partner di Best Golf su eventi, promozioni e offerte disponibili sulla piattaforma Best Golf.</li>
                </ol>

                <div className="d-flex align-items-center ">
                  <div>
                    <i className="fas fa-bullhorn fa-2x color-primary" aria-hidden="true"></i>
                  </div>
                  <div className="pl-3">
                    <h5><strong>Inviare messaggi promozionali di terze parti</strong></h5>
                  </div>
                </div>
                <p>I dati sono utilizzati per attività di profilazione e marketing, in particolare per: </p>
                <ol>
                  <li>Elaborare i dati in base al comportamento d’acquisto per creare liste profilate; </li>
                  <li>Utilizzare le liste profilate per finalità di co-marketing effettuato in collaborazione con organizzazioni interessate.</li>
                </ol>
                <p><em>Best Golf raccoglie e tratta i tuoi dati in qualità di Titolare del Trattamento, conformemente a quanto previsto dal Regolamento (UE) 2016/679 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali. Per avere maggiori dettagli sul trattamento dati e conoscere meglio i tuoi diritti,
                  leggi la nostra “Informativa estesa sulla Protezione dei Dati personali”.</em></p>
                <p>Andando avanti sul processo di prenotazione/acquisto, dichiari di aver preso visione dei seguenti documenti e di accettarne i termini e le condizioni contenute:</p>
                <p><a href="https://italybestgolf.com/doc_bg/2022-03-Informativa-estesa.pdf" target="_blank"><strong className="color-primary">Informativa sulla Protezione dei Dati personali</strong></a></p>
                <p><a href="https://italybestgolf.com/doc_bg/2022-03-Termini-condizioni.pdf" target="_blank"><strong className="color-primary">Termini e condizioni di prenotazione e vendita online</strong></a></p>
              </div>



               */}

        </Modal>
        <div className="col-12 d-flex flex-column" style={{ rowGap: "1rem" }}>
          <div className="text-gray pt-4 text-center">
            <p className="p-0 m-0">
            {intl.formatMessage({id:"Fornisci il consenso al trattamento dei tuoi dati personali:"})}
            </p>
            <br />
            <b className="scritteviiola" onClick={openDocumentHandler}>
              {" "}
            </b>
          </div>
          <div className="d-inline-flex justify-content-around flex-row text-center" style={{ columnGap: "1rem" }}>
            <button className="btn btn-primary" onClick={elHandler}>
            {intl.formatMessage({id:"Conferma tutto"})}
            </button>
            <button className="btn btn-secondary" onClick={openHandler}>
            {intl.formatMessage({id:"Visualizza e modifica"})}
            </button>

          </div>
          {isOpen && (
            <div className="d-inline-flex flex-column">
              {!gdprs.privacy && (
                <div
                  className="
				d-flex  align-items-center"
                >
                  <Checkbox checked={er} onClick={erHandler} id="er" />
                  <text>{intl.formatMessage({id:"Erogare il servizio (obbligatorio)"})}
 </text>
                </div>
              )}
              <div className="d-inline-flex flex-column">
                {!gdprs.newsletter && (
                  <div
                    className="
				d-flex  align-items-center"
                  >
                    <Checkbox checked={co} onClick={coHandler} id="co" />
                    <text>{intl.formatMessage({id:"Comunicare le novità Best Golf"})}</text>
                  </div>
                )}
              </div>
              {!gdprs.info && (
                <div
                  className="
				d-flex  align-items-center"
                >
                  <Checkbox checked={el} onClick={elHandler} id="el" />
                  <text>{intl.formatMessage({id:"Inviare messaggi promozionali di terze parti"})}</text>
                </div>
              )}
            </div>
          )}
          <div
            onClick={() => {
              let consents = [];
              if (el || gdprs.info) {
                consents.push({
                  name: "info",
                  text: "Inviare messaggi promozionali di terze parti",
                  slug: "info",
                });
              }
              if (er || gdprs.privacy) {
                consents.push({
                  name: "privacy",
                  text: "Erogare il servizio (obbligatorio)",
                  slug: "privacy",
                });
              }
              if (co || gdprs.newsletter) {
                consents.push({
                  name: "newsletter",
                  text: "Comunicare le novità Best Golf",
                  slug: "newsletter",
                });
              }
              if (props.returnConsents) {
                props.returnConsents(consents);
              } else {
                PostGpdr(
                  "EJVXEFXCGCXRHFFA5D9IPO0CGQZCGEUTNWPDVZQCUNKWMIPGAXAKLXYS1BABPNNL",
                  "iscrizione",
                  "iscrizione",
                  [...consents]
                );
              }
            }}
          >
            {(er || gdprs.privacy) && (
              <div className="my-3">
                {intl.formatMessage({id:"Andando avanti dichiari di aver letto l’informativa -Perché trattiamo i tuoi dati?- che puoi consultare facendo click"})}
                 <b><text style={{cursor:"pointer"}} className="color-primary" onClick={openDocumentHandler}>  {intl.formatMessage({id:"qui"})}</text></b>.
              </div>
            )}
            {(er || gdprs.privacy) && <div>{props.children}</div>}
          </div>
        </div>
      </>
    );
  } else if (gdprs.privacy) {
    return <div>{props.children}</div>;
  }
};
export default Gdpr;
