import React from "react";
import { useHistory } from "react-router";

const BackButton = () => {
  const history = useHistory();
  return (
    <div class="ibg-back "
      onClick={() => {
        history.replace("/");
      }}
    > 
        <i className="fa fa-angle-left" aria-hidden="true"></i>
    </div>
  );
};
export default BackButton;
