import React, { useContext } from "react";
import { useState, useEffect } from "react";
import SearchContext from "../../store/search-context";
import { Modal } from "react-rainbow-components";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory } from "react-router";
const AdvancedTextSearch = (props) => {
  const [text, setText] = useState("");
  const [shouldBeOpen, setShouldBeOpen] = useState(false);
  const [suggestionsx, setSuggestionsx] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const srcCtx = useContext(SearchContext);
  //! ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const textHandler = (e) => {
    setText(e.target.value);
  };
  const submitPlaceCallBack = async (event) => {
    const results = await geocodeByAddress(event);

    const latLng = await getLatLng(results[0]);
    if (!props.header) {
      console.log("LAT E LONG", latLng);
      const dataSend = new Date();
      const dataSendString = dataSend.toISOString();
      console.log("LE DATE DI DIOZ", dataSend, dataSendString);
      srcCtx.send(event);
      history.push({
        pathname: "/pacchetti",
        state: {
          lat: latLng.lat,
          lon: latLng.lng,
          datain: dataSendString,
          dataout: dataSendString,
        },
      });
    }
    if (props.header) {
      props.liftLat(latLng.lat);
      props.liftLon(latLng.lng);
    }
  };
  const shouldBeOpenHandler = () => {
    setShouldBeOpen(!shouldBeOpen);
  };
  const handleSelect = async (value) => {
    // const results = await geocodeByAddress(value);

    // const latLng = await getLatLng(results[0]);

    if (props.header) {
      props.onPick(value);
    }
    setText(value);
    submitPlaceCallBack(value);
		  setSuggestionsx([])
  };
  const onClickPacchettoHandler = (url) => {
    history.replace(`pacchetto/${url}`);
    setSuggestionsx([]);
  };

  function getAttribute(obj, key, lang) {
    let f = obj.find((v) => v.key == key && v.lang == lang);
    return f.value;
  }

  const onChangeHandler = (text) => {
    let matches = [];
    console.log(props.toSearch, "ELEMENTI");
    if (text != "") {
      matches = props.toSearch.filter((struttura) => {
        if (
          struttura.elementi.some((s) =>
            // s.key === "titolo" &&
            // s.lang === "it_IT" &&
            // s.value.toLowerCase().includes(text.toLowerCase())
            // console.log

            s.nome != undefined
              ? s.nome.toLowerCase().includes(text.toLowerCase())
              : false
          )
        ) {
          console.log(struttura.meta);
          struttura.nome = getAttribute(struttura.meta, "titolo", "it_IT");
          console.log(struttura);
          return struttura;
        }
      });
    }
    console.log("TOSEARCH", matches);
    setSuggestionsx(matches);
    console.log("TEXT", text);
    setText(text);
  };
  useEffect(() => {
    console.log("TI PREEEEEEEEEEEEEEEEEEEEEEEEEGO", suggestionsx);
    // console.log(props.toSearch.map((val)=>{
    //   val.elementi.some((s)=>{
    //   if(s.nome!=undefined){
    //     console.log("Ssss",s.nome)
    //   }
    //   })
    // }))
  }, [suggestionsx]);
  // useEffect(() => {
  //   props.toSearch.filter((val) => {
  //     // val.meta.filter((val) => {
  //     //   console.log(
  //     //     val.key === "titolo" &&
  //     //       val.lang === "it_IT" &&
  //     //       val.value.toLowerCase().includes("test")
  //     //   );
  //     // });
  //    console.log("SOME",val.meta.some((val)=>(val==='titolo') ))
  //   });
  //   setIsLoading(true);
  // }, [props]);
  //! ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  if (props.modex === 1) {
    return (
      <>
        {props.mode === "home" ? (
            <button className="btn btn-primary btn-sm-block" onClick={shouldBeOpenHandler}>
              Scegli la tua vacanza
            </button>
        ) : (
          <input
            type="text"
            placeholder="Dove vuoi andare?"
            className="form-control"
            value={text}
            readOnly
            style={{ borderRadius: "7px", backgroundColor: "white" }}
            onClick={shouldBeOpenHandler}
          />
        )}

        {shouldBeOpen ? (
          <Modal isOpen={shouldBeOpen} onRequestClose={shouldBeOpenHandler}>
                <PlacesAutocomplete
                  value={text}
                  onChange={(e) => onChangeHandler(e)}
                  onSelect={handleSelect}
                  searchOptions={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "IT" },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div>
                      <div class="input-header">Dove vuoi andare?</div>
                      <div className="d-flex">
                        <input
                          style={{
                            width: "100%",
                            border: "none",
                          }}
                          
                          className="form-control"
                          defaultValue={props.defVal}
                          {...getInputProps({
                            placeholder: "Scrivi l'indirizzo",
                          })}
                        ></input>
                      </div>
                      <div>
                        {loading ? <div>...loading</div> : null}
                        <ul className="autoCompItemDisplayer-map">
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "blue"
                                : "white",
                            };
                            const className = "autoCompleteElement";
                            console.log("suggestions", suggestion);
                            return (
                              <li
                                onClick={() => {
                                  submitPlaceCallBack(suggestion.description);
                                }}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                  className,
                                })}
                              >
                                <div className="">
                                  <img
                                    width={20}
                                    src={process.env.PUBLIC_URL + "mapPin.png"}
                                  />
                                  {suggestion.description}
                                </div>
                              </li>
                            );
                          })}
                          {suggestionsx.slice(0, 10).map((val) => (
                            <li
                              className="autoCompleteElement d-flex"
                              style={{ width: "100%" }}
                              onClick={() => {
                                onClickPacchettoHandler(val.ssid);
                              }}
                            >
                              <img
                                width={"20%"}
                                src={
                                  val.imgs[0]
                                    ? val.imgs[0].img
                                    : process.env.PUBLIC_URL + "placeholder.png"
                                }
                              />
                              <div className="pl-3">{val.nome} </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
          </Modal>
        ) : null}
      </>
    );
  } else if (props.modex === 2) {
    return (
      <PlacesAutocomplete
        value={text}
        onChange={(e) => onChangeHandler(e)}
        onSelect={handleSelect}
        searchOptions={{
          types: ["(regions)"],
          componentRestrictions: { country: "IT" },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div class="input-header">Dove vuoi andare?</div>
            <div className="d-flex">
              <input
                style={{
                  width: "100%",
                  border: "none",
                }}
                
                className="form-control"
                defaultValue={props.defVal}
                {...getInputProps({
                  placeholder: "Scrivi l'indirizzo",
                })}
              ></input>
              <div
                onClick={shouldBeOpenHandler}
                style={{
                  width: "10%",
                  marginTop: "0.4rem",
                  paddingLeft: "0.8rem",
                  backgroundColor: "white",
                  color: "rgba(167, 24, 86, 1)",
                  fontWeight: "700",
                  paddingTop: "0.8rem",
                  height: "45px",
                  paddingRight: "0.1rem",
                }}
              >
                X
              </div>
            </div>
            <div>
              {loading ? <div>...loading</div> : null}
              <ul className="autoCompItemDisplayer-map">
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "blue" : "white",
                  };
                  const className = "autoCompleteElement";
                  console.log("suggestions", suggestion);
                  return (
                    <li
                      onClick={() => {
                        submitPlaceCallBack(suggestion.description);
                      }}
                      {...getSuggestionItemProps(suggestion, {
                        style,
                        className,
                      })}
                    >
                      <div className="">
                        <img
                          width={20}
                          src={process.env.PUBLIC_URL + "mapPin.png"}
                        />
                        {suggestion.description}
                      </div>
                    </li>
                  );
                })}
                {suggestionsx.slice(0, 10).map((val) => (
                  <li
                    className="autoCompleteElement d-flex"
                    style={{ width: "100%" }}
                    onClick={() => {
                      onClickPacchettoHandler(val.ssid);
                    }}
                  >
                    <img
                      width={"20%"}
                      src={
                        val.imgs[0]
                          ? val.imgs[0].img
                          : process.env.PUBLIC_URL + "placeholder.png"
                      }
                    />
                    <div className="pl-3">{val.nome} </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
};
export default AdvancedTextSearch;
