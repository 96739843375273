import React, { useEffect, useRef } from "react";
import { IntlProvider } from "react-intl";
import it from "../../locale/it.json";
import en from "../../locale/en.json";
import de from "../../locale/de.json";
import { useCookies } from "react-cookie";

export const Context = React.createContext();

export const IntlProviderWrapper = (props) => {

  const [LocCookie, setLocCookie] = useCookies(["language"]);
  const date_expires = new Date();
  date_expires.setDate(date_expires.getDate() + 999);
  let language_cookie;
  const refRender = useRef(true);

  const setCookie = (lang) => {
    if (lang === "it-IT" || lang === "it") {
      setLocCookie("language", "it", { path: "/", expires: date_expires });
    } else if (lang === "de_DE" || lang === 'de') {
      setLocCookie("language", "de", { path: "/", expires: date_expires });
    } else {
      setLocCookie("language", "en", { path: "/", expires: date_expires });
    }
    window.location.reload();
  };

  const checkAvailableLanguages = () => {
    console.log(window.location.pathname, 'MATCH');
    const splitted = window.location.pathname.split("/");
    const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

    console.log(userLocale, 'NAVIGATOR');
    if (splitted.length > 1) {
      if (splitted[1] == 'it'){
        setLocCookie("language", "it", { path: "/it", expires: date_expires });
      }
      if (splitted[1] == 'de'){
        setLocCookie("language", "de", { path: "/de", expires: date_expires });
      }

      if (splitted[1] == "" || splitted[1].includes("en")){
        setLocCookie("language", "en", { path: "/", expires: date_expires });
      } 
    }
    console.log(splitted, "MATCH")
  }

  useEffect(() => {
    console.log(LocCookie, "MATCH");
    if (!LocCookie.language){
      setLocCookie("language", "en", { path: "/", expires: date_expires });
    }
    checkAvailableLanguages();
    refRender.current = false;
  }, []);

  if (LocCookie.language === undefined) {
    if (refRender.current) {
      const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
      if (locale === "it-IT" || locale === "it") {
        language_cookie = "it";
      } else if (locale === "de_de" || locale === "de") {
        language_cookie = "de";
      } else {
        language_cookie = "en";
      }
    }
  } else {
    language_cookie = LocCookie.language;
  }
  const contextValue = {
    language_cookie: language_cookie,
  };

  return (
    <Context.Provider value={{ language_cookie, setCookie }}>
      <IntlProvider
        messages={language_cookie === "it" ? it : language_cookie === "de" ? de : en}
        locale={language_cookie}
        defaultLocale={language_cookie}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

