import React, { useState, useEffect } from "react";
import axios from "axios";
import { useContext } from "react";
import AuthContext from "../components/store/auth-context";
const useGetGdpr = () => {
  const { token, session_token } = useContext(AuthContext);
  const [accepted, setAccepted] = useState([]);
  const [gdpr, setGdpr] = useState({
    privacy: false,
    info: false,
    gdprs: false,
  });
  useEffect(() => {
    if (token) {
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_API_ENDPOINT}/privacy/consents?access_token=${token}`,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + session_token,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setAccepted(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }, [session_token,token]);
  useEffect(() => {
    if (accepted.length > 0) {
      setGdpr({
        privacy: accepted.includes("privacy"),
        info: accepted.includes("info"),
        newsletter: accepted.includes("newsletter"),
      });
    }
  }, [accepted, token]);
  return gdpr;
};
export default useGetGdpr;
