import React from "react";
import Logo from "./icon512x512.png";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { useIntl, FormattedMessage } from "react-intl";
const LogoPart = (props) => {
  const intl = useIntl();
  return (
    <>
      <div className="d-flex  flex-column justify-content-center align-items-center">
        <img width={150} src={Logo} />
       
       
		  <div className="mt-3">
		  <LanguageSelector/>
		  </div>
      </div>
    </>
  );
};
export default LogoPart;
