import {React, useContext} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Context } from "../../store/intl-context";
const FollowUs = () => {
  const intl = useIntl();
  const { language_cookie: language } = useContext(Context);
  const facebook = (
    <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
  );
  const instagram = <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>;
  const linkedin = <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i>;

  const lng = language == 'en' ? '' : '/'+language;

  const menu = [    
    { nome: "Registrazione Tour Operator", link: lng+"/registra-tour-operator" },
  { nome: "Registrazione Servizi Turistici", link: lng+"/registraoperatore" } ];
  
  const socials = [
    { icon: facebook, link: "https://www.facebook.com/Italybestgolf/" },
    {
      icon: linkedin,
      link: "https://www.linkedin.com/in/italy-best-golf-9426a7167/",
    },
    { icon: instagram, link: "https://www.instagram.com/italybestgolf/" },
  ];
  return (
    <>
      <div className="d-block text-center  ">
      <h5 className="labelSmall"><FormattedMessage id="partner" /></h5>
      <div className="d-flex flex-column justify-content-center">
        {menu.map((val) => (
          <a className="p-1" style={{ color: "white" }} href={val.link} >
            {intl.formatMessage({id:val.nome})}
          </a>
        ))}
        </div>

       
      </div>
    </>
  );
};

export default FollowUs;
