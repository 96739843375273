import { useHistory } from "react-router";
import { useLastLocation } from "react-router-last-location";

const GoBackButton = () => {
  const lastLoc = useLastLocation();
  const history = useHistory();
  
  return (
    <>
      {lastLoc && history.location.pathname != "/" && (
        <button className="time_header-back" onClick={() => history.goBack()}>
          <i
            style={{ cursor: "pointer" }}
            className="fa fa-angle-left"
            aria-hidden="true"
          ></i>
        </button>
      )}
    </>
  );
};
export default GoBackButton;
