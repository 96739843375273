import { useState, useEffect } from "react";
import useHttp from "../../../hooks/use-http";
const InformativaDisplayer = ({ nome }) => {
  const { sendRequest: sendPrivacyRequest, isLoading: isPrivacyLoading } =
    useHttp();

  const [infografica, setInfografica] = useState({});

  const managePrivacy = (infos) => {
    const app = infos.data.filter((fil) => fil.name === nome);
    setInfografica(...app);
  };

  useEffect(() => {
    sendPrivacyRequest({
      url: process.env.REACT_APP_API_ENDPOINT+"/privacy/document",
      manageData: managePrivacy,
    });
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: infografica.infografica }}></div>
  );
};
export default InformativaDisplayer;
