import React, { useState, useEffect } from "react";
import { useContext } from "react";
import SearchContext from "../store/search-context";
import { Modal } from "react-rainbow-components";
import minus from "./minus.svg";
import plus from "./add.svg";
import Occupazione from "../../elements/Occupazione";
import { formatRoomString, formatRoomQuery } from "../function/format-rooms";
const PeopleHeader = (props) => {
  const srcCtx = useContext(SearchContext);
  const [isModal, setIsModal] = useState(false);
  const modalHandler = () => {
    setIsModal(!isModal);
  };
  const [numbers, setNumbers] = useState(
    srcCtx.numbers != "" ? srcCtx.numbers : 1
  );
  const numberPlus = () => {
    if (numbers < 4) setNumbers((n) => n + 1);
  };
  const numberLess = () => {
    if (numbers > 1) setNumbers((n) => n - 1);
  };
  const submit = () => {
    if (props.idCanale === 3) {
      srcCtx.sendNumbers(numbers);
      setIsModal(false);
    } else if (props.idCanale === 8) {
      srcCtx.sendRoomsQuery(roomQuery);
      srcCtx.sendRoomsString(roomString);
      setIsModal(false);
    }
  };
  const [roomString, setRoomString] = useState(srcCtx.roomsString);
  const [roomQuery, setRoomQuery] = useState(srcCtx.roomsQuery);
  const [stanze, setStanze] = useState([
    { adulti: 2, bambini: 0, eta: [], datiCamera: { prezzo: 0 } },
  ]);
  const liftStanze = (stanze) => {
    setStanze([...stanze]);
    console.log("LIFT STANZE HEADER", [...stanze]);
    setRoomString(formatRoomString([...stanze]));
    setRoomQuery(formatRoomQuery([...stanze]));
		  srcCtx.sendNumbers(stanze.length)
  };
  useEffect(() => {
    submit();
  }, [roomString, roomQuery]);
  return (
    <>
      <div onClick={modalHandler}>


        <span className="text-small d-none d-md-block">
         <img  src={process.env.PUBLIC_URL + "/persone.svg"} className="icon-header" />
          {srcCtx.numbers != "" ? srcCtx.numbers : "--"}
        </span>
        <span className="text-small d-block d-md-none">
          <img  src={process.env.PUBLIC_URL + "/persone.svg"} className="icon-header"/>
        </span>



        

      </div>
      <Modal isOpen={isModal} onRequestClose={submit}>
        {props.idCanale === 3 && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="col-12 text-center pb-3"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <b>Scegli il numero di giocatori</b>
            </div>
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ gap: "10px" }}
            >
              <img onClick={numberLess} src={minus} width={25} />
              <text>
                <b className="color-primary">{numbers}</b>
              </text>
              <img onClick={numberPlus} src={plus} width={35} />
            </div>
          </div>
        )}

        {props.idCanale === 8 && (
          <Occupazione submit={liftStanze} stanze={stanze} mode={3} />
        )}
      </Modal>
    </>
  );
};
export default PeopleHeader;
