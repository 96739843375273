export const formatRoomString = (stanze) => {
  var adultiTotal = stanze
    .map((val) => val.adulti)
    .reduce((prev, next) => prev + next);
  var bambiniTotal = stanze
    .map((val) => val.bambini)
    .reduce((prev, next) => prev + next);
  var stanzeTotal = stanze.length;
  var finalString = `${adultiTotal} Adulti,${bambiniTotal} Bambini,${stanzeTotal} Camere`;
  console.log(finalString);
  console.log("FINAL STRING", finalString);
  return finalString;
};

export const formatRoomQuery = (stanze) => {
  var tempQuery = JSON.stringify(
    stanze.map((val) => ({
      adults: val.adulti,
      extrabed: val.bambini > 0 ? true : false,
      age: val.bambini > 0 ? val.eta[0] : "",
      cot: "false",
    }))
  );
  console.log("FINAL QUERY",tempQuery)
  return tempQuery;
};
