import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import AuthContext from "../components/store/auth-context";
const useGetGolf = () => {
const authCtx=useContext(AuthContext)
  const [campi, setCampi] = useState([]);
  useEffect(() => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT+"/golf/circolo",
      headers: {
        Accept: "application/json",
				  'Authorization':"Bearer "+authCtx.session_token
      },
    };

    axios(config)
      .then(function (response) {
        setCampi(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);
		return campi;
};
export default useGetGolf;
