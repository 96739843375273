import cartLogo from "./cart.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import CartContext from "../store/cart-context";

import useHttp from "../../hooks/use-http";
import { useEffect } from "react";

const CartHeader = () => {
  const { cartID, cartElements, sendCartElements, sendCartTotal } =
    useContext(CartContext);

  const { sendRequest: sendCartRequest, isLoading: isCartLoading } = useHttp();

  const manageCart = (cart) => {
		  console.log("CART ELEMENSZ",cart)
    sendCartElements(cart[0].elementi.filter((el)=>el.pagato < 1));
    sendCartTotal(cart[0].totale);
  };

  useEffect(() => {
    if (cartElements.length <= 0 && localStorage.getItem("cartID")) {
      const data = {
        sessionid: localStorage.getItem("cartID"),
        pagato: 0,
        id_acl_user: "",
        id_pacchetto_dinamico: "",
        cognome: "",
        email: "",
        telefono: "",
      };
      sendCartRequest({
        url: process.env.REACT_APP_API_ENDPOINT+"/shoppingcart/ricercacarrelli",
        methodH: "POST",
        bodyH: data,
        manageData: (e) => {
          manageCart(e.data);
        },
      });
    }
  }, [cartID]);

  return (
    <>
      <div className="cart-badge-position badge-primary badge-pill badge">
        <b>{cartElements.length}</b>
      </div>
      <Link to={{ pathname: "/time/checkout" }}>
        <img  src={cartLogo} className="icon-header" />
      </Link>
    </>
  );
};
export default CartHeader;
