import React, { useState, useEffect } from "react";
import axios from "axios";
const CartContext = React.createContext({
  cartID: "",
  cartTotal: 0,
  cartElements: [],

  sendCartID: (value) => {},
  sendCartElements: (value) => {},
  sendCartTotal: (value) => {},
  removeCartElement: (id) => {},

  cleanCart: () => {},
});

export const CartContextProvider = (props) => {
  const initialCart = localStorage.getItem("cartID");
  const [cartID, setCartID] = useState(initialCart);
  const [cartElements, setCartElements] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  const cartIDHandler = (val) => {
    console.log("ID CHE PASSO", val);
    localStorage.setItem("cartID", val);
    setCartID(val);
  };

  const cartElementsHandler = (val) => {
    setCartElements(val);
  };

  const cartTotalHandler = (val) => {
    setCartTotal(val);
  };

  const cleanCartHandler = () => {
    setCartID("");
    setCartElements([]);
    alert("STO SVUOTANDO IL CARRELLO -VERSIONE DEMO");
    localStorage.setItem("cartID", "");
  };

  const removeCartElement = (id) => {
    console.log("REMOVE CART HANDLER",cartElements);
  };

  const contextValue = {
    cartTotal: cartTotal,
    cartID: cartID,
    cartElements: cartElements,
    sendCartID: cartIDHandler,
    sendCartElements: cartElementsHandler,
    sendCartTotal: cartTotalHandler,
    cleanCart: cleanCartHandler,
    removeCartElement: removeCartElement,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};
export default CartContext;
