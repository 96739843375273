import React, { useContext } from "react";
import AuthContext from "../store/auth-context";
import SearchContext from "../store/search-context";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import ModalLogin from "../Login/ModalLogin";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import Modal from "../Login/Modal";
import HotelBookingPanel from "../time/HotelBookingPanel";
import useGetPacchetti from "../../hooks/get-pacchetti";
import logo from "./logo1.png";
import Drawer from "@mui/material/Drawer";

import WrappedLogin from "../WrappedLogin/WrappedLogin";

import CartContext from "../store/cart-context";

import GoBackButton from "./GoBackButton/GoBackButton";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import { DatePicker } from "react-rainbow-components";
import { Data } from "@react-google-maps/api";
import Occupazione from "../../elements/Occupazione";
import { formatRoomString } from "../function/format-rooms";
import HeaderGolf from "./Header-Golf";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import CartHeader from "../CartHeader/CartHeader";

import TimeContext from "../store/time-context";

function StandardHeader(props) {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(CartContext);
  const { widget } = useContext(TimeContext);
  const facebook = (
    <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
  );
  const instagram = <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>;
  const linkedin = <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i>;
  const socials = [
    { icon: facebook, link: "https://www.facebook.com/Italybestgolf/" },
    {
      icon: linkedin,
      link: "https://www.linkedin.com/in/italy-best-golf-9426a7167/",
    },
    {
      icon: instagram,
      link: "https://www.instagram.com/p/Bl2sPH2B-rM/?utm_source=ig_share_sheet&igshid=1dg5hphrkl3ht&r=wa1",
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const openHandler = () => {
    setIsOpen((old) => !old);
  };
  useEffect(() => {
    if (cartCtx.cartID === "" || cartCtx.cartID === null) {
      console.log("USE EFFECT CONTEXT");
      var config = {
        method: "post",
        url: process.env.REACT_APP_API_ENDPOINT+"/shoppingcart/creacarrello",
        headers: {
          Accept: "application/json",
        },
        data: {
          sessionid: "",
          pagato: 0,
          id_acl_user: "",
          id_pacchetto_dinamico: "",
          cognome: "",
          email: "",
          telefono: "",
        },
      };

      axios(config)
        .then(function (response) {
          cartCtx.sendCartID(response.data.data.sessionid);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [cartCtx.cartID]);
  return (
    <div className="row header-row header-structure ">
      <div className="col-4 px-0 d-flex align-items-center">
        <div className="logo-header">
          {!widget && (
            <Link to={{ pathname: "/" }}>
              <img src={logo} alt="" className="main-logo" />
            </Link>
          )}
        </div>
        <GoBackButton />

        <div className="d-none d-md-block btn-best-time">
          <Link to={{ pathname: "/time" }} className="text-white">
            BesTime
          </Link>
        </div>
        <div className="d-block d-md-none btn-best-time">
          <Link to={{ pathname: "/time" }} className="text-white">
            BT
          </Link>
        </div>
      </div>
      <div className="left-side menu-centrale">
        <div className="menu-trigger"></div>
        <nav className="style-1 d-flex navigation navbar-centrale-header">
          {/* <ul className="header-nav">
         <li>
           <Link className="header-link" to={{ pathname: "/" }}>
             Home
           </Link>
         </li>
         <li>
           {" "}
           <Link
             className="header-link"
             to={{ pathname: "/campi-da-golf" }}
           >
             Campi da golf
           </Link>
         </li>
         <li>
           <Link className="header-link" to={{ pathname: "/pacchetti" }}>
             Pacchetti
           </Link>
         </li>
        </ul> 

          <ul className="header-nav hide-mobile">
            <li>
              <Link
                className="header-link"
                to={{
                  pathname: "/",
                }}
              >
                Green Fee
              </Link>
            </li>
            <li>
              {" "}
              <Link
                className="header-link"
                to={{
                  pathname: "/campi-da-golf",
                }}
              >
                Vacanze
              </Link>
            </li>
            <li>
              <Link
                className="header-link"
                to={{
                  pathname: "/pacchetti",
                }}
              >
                Gare
              </Link>
            </li>
          </ul>*/}
        </nav>
      </div>

      <>
        {/* {!props.isLoggedIn && (
          <ModalLogin
            isModal={props.isModal}
            onClickModal={props.onClickModal}
          ></ModalLogin>
        )} */}
        <WrappedLogin />
      </>
    </div>
  );
}

//! ///////////////////////////////////////////////////////////////////////
//

function SearchdHeader(props) {
  const srcCtx = useContext(SearchContext);
  const [shouldBeModal, setShouldBeModal] = useState(false);
  const [shoudlBeOccupation, setShouldBeOccupation] = useState(false);
  const occupationHandler = () => {
    setShouldBeOccupation(!shoudlBeOccupation);
  };
  const modalHandler = () => {
    setShouldBeModal(!shouldBeModal);
  };
  const onClickPacchettoHandler = (url) => {
    history.replace(`pacchetto/${url}`);
    setSuggestionsx([]);
  };
  const [searched, setSearched] = useState("Golf");
  const setGolfButton = (e) => {
    setSearched("Golf");
    srcCtx.sendButtonValue("Golf");
  };
  const setPacchettoButton = (e) => {
    setSearched("Pacchetto");
    srcCtx.sendButtonValue("Pacchetto");
  };
  const setGaraButton = (e) => {
    setSearched("Gara");
    srcCtx.sendButtonValue("Gara");
  };
  const [term, setTerm] = useState("");
  const [data, setData] = useState(new Date());
  const [people, setPeople] = useState(0);
  const history = useHistory();
  const [text, setText] = useState("");
  const [suggestionsx, setSuggestionsx] = useState([]);
  const [type, setType] = useState("");
  const [toSearch, setTosearch] = useState({});

  //? //////////////////////////////////////
  const submitPlaceCallBack = async (event) => {
    const results = await geocodeByAddress(text);

    srcCtx.send(text);

    const options = { year: "numeric", month: "numeric", day: "numeric" };

    srcCtx.sendNumbers(1);
    if (data) {
      srcCtx.sendData(data.toISOString().split("T", 1)[0]);
    } else {
      srcCtx.sendData("");
    }

    const latLng = await getLatLng(results[0]);
    srcCtx.sendLat(latLng.lat);
    srcCtx.sendLon(latLng.lng);
    // history.replace({ pathname: "/pacchetti", state: latLng });
  };
  const submitCallbBack = (event) => {
    setTosearch(event);
    history.replace(`/pacchetto/${event[0].slug}`);
  };
  const onChangeHandler = (text) => {
    let matches = [];
    if (text != "") {
      matches = props.toSearch.filter((struttura) => {
        if (
          struttura.elementi.some((s) =>
            // s.key === "titolo" &&
            // s.lang === "it_IT" &&
            // s.value.toLowerCase().includes(text.toLowerCase())
            s.nome != undefined
              ? s.nome.toLowerCase().includes(text.toLowerCase())
              : false
          )
        ) {
          return struttura;
        }
      });
    }
    setSuggestionsx(matches);
    setText(text);
  };
  const onClickItemHandler = (event, type) => {
    setText(event);
    setType(type);
    setSuggestionsx([]);
  };
  const handleSelect = async (value) => {
    // const results = await geocodeByAddress(value);

    // const latLng = await getLatLng(results[0]);

    setText(value);
    setSuggestionsx([]);
  };
  useEffect(() => {
    if (props.preTerm) setTerm(props.preTerm);
  }, [props.preTerm]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    submitPlaceCallBack(text);
    var tempQuery = JSON.stringify(
      stanze.map(
        (val) => ({
          adulti: val.adulti,
          extrabed: val.bambini > 0 ? true : false,
          age: val.bambini > 0 ? val.eta[0] : "",
          cot: "false",
        })

        // `{'tipo':'dbl','required':'${val.adulti}','extrabed':'${
        //   val.bambini > 0 ? true : false
        // }','age':'${val.bambini > 0 ? val.eta[0] : ""}'}`
      )
    );
    srcCtx.sendRoomsQuery(tempQuery);
    shouldBeOpenHandler();
    // if(toSearch[0]){
    // }
    // // history.replace({pathname:'/campi-da-golf',state:{searchterm:text}})
  };
  const [shouldBeOpen, setShouldBeOpen] = useState(false);
  const shouldBeOpenHandler = () => {
    setShouldBeOpen(!shouldBeOpen);
  };
  const [stanze, setStanze] = useState([]);
  const peopleHandler = (stanze) => {
    srcCtx.sendRoomsString(formatRoomString(stanze));
    setStanze(stanze);

    occupationHandler();
  };
  return (
    <div className="row header-row d-flex align-items-center">
      <div id="logo">
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, .1)",
            border: "none",
            borderRadius: "50%",
            border: "1px solid rgba(255, 255, 255, .1),",
            width: "30px",
            height: "30px",
            textAlign: "center",
            color: "white",
            fontWeight: "700",
            fontSize: "1.2em",
          }}
          onClick={() => {
            history.replace("/");
          }}
        >
          {"<"}
        </div>
      </div>

      <div className="left-side menu-centrale">
        <div className=" d-flex">
          <div
            style={{
              padding: "3px",
              borderRadius: "30px",
              backgroundColor: "rgba(255, 255, 255, .1)",
              width: "80vw",
              height: "43px",
              border: "none",
              height: "30px",
              marginTop: "0.4rem",
              color: "white",
            }}
            className="d-flex"
            onClick={shouldBeOpenHandler}
          >
            <div style={{ width: "45%", overflow: "hidden" }}>{term}</div>
            <div style={{ width: "40%" }}>
              <span className="text-small">
                <img width={24} src={process.env.PUBLIC_URL + "/calendar.svg"} />
                {"/"}
                {srcCtx.data != "" ? srcCtx.data : "--"}
              </span>
            </div>
            <div style={{ width: "14%", textAlign: "end", overflow: "hidden" }}>
              <span className="text-small">
                <img width={24} src={process.env.PUBLIC_URL + "/persone.svg"} />
                {"/"}
                {srcCtx.numbers != "" ? srcCtx.numbers : "--"}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            right: "18px",
            top: "17px",
            color: "white",
          }}
        ></div>
        <div
          style={{
            position: "absolute",
            right: "60px",
            top: "17px",
            color: "white",
          }}
        ></div>
        <nav className="style-1 d-flex navigation navbar-centrale-header">
          {/* <ul className="header-nav">
         <li>
           <Link className="header-link" to={{ pathname: "/" }}>
             Home
           </Link>
         </li>
         <li>
           {" "}
           <Link
             className="header-link"
             to={{ pathname: "/campi-da-golf" }}
           >
             Campi da golf
           </Link>
         </li>
         <li>
           <Link className="header-link" to={{ pathname: "/pacchetti" }}>
             Pacchetti
           </Link>
         </li>
        </ul> */}

          <ul className="header-nav hide-mobile">
            <li>
              <Link
                className="header-link"
                to={{
                  pathname: "/",
                }}
              >
                Green Fee
              </Link>
            </li>
            <li>
              {" "}
              <Link
                className="header-link"
                to={{
                  pathname: "/campi-da-golf",
                }}
              >
                Vacanze
              </Link>
            </li>
            <li>
              <Link
                className="header-link"
                to={{
                  pathname: "/pacchetti",
                }}
              >
                Gare
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <>
        {/* <div className="right-side d-flex ">
          
        </div> */}
      </>
      {shouldBeOpen ? (
        <form onSubmit={onSubmitHandler}>
          <div className="hide-desktop d-flex row">
            {/* <input
        onChange={(e) => onChangeHandler(e.target.value)}
        placeholder="Cerca un circolo"
        value={text}
      /> */}
            <div className="container">
              <div
                className="container text-center"
                style={{ margin: "1rem 0", color: "white" }}
              >
                <h3 style={{ fontWeight: "100" }}>Modifica ricerca</h3>
              </div>
              <div className="col-lg-6">
                <div
                  className="d-flex"
                  style={{
                    textAlign: "center",
                    columnGap: 10,
                    marginBottom: 25,
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <button
                      onClick={setGolfButton}
                      type="button"
                      className={
                        srcCtx.buttonValue === "Golf"
                          ? "round-selection searchButtons-active"
                          : "round-selection searchButtons"
                      }
                    >
                      GREEN FEE
                    </button>
                    <button
                      type="button"
                      onClick={setPacchettoButton}
                      className={
                        srcCtx.buttonValue === "Pacchetto"
                          ? "round-selection searchButtons-active"
                          : "round-selection searchButtons"
                      }
                    >
                      VACANZE
                    </button>
                    <button
                      type="button"
                      onClick={setGaraButton}
                      className={
                        srcCtx.buttonValue === "Gara"
                          ? "round-selection searchButtons-active"
                          : "round-selection searchButtons"
                      }
                    >
                      GARE
                    </button>
                  </div>
                </div>

                <div>
                  <input
                    type="text"
                    placeholder={srcCtx.term}
                    className="form-control"
                    value={text}
                    readOnly
                    style={{ borderRadius: "7px", backgroundColor: "white" }}
                    onClick={modalHandler}
                  />
                  {shouldBeModal ? (
                    <Modal>
                      <div className="show-overlay">
                        <div className="card" style={{ height: "100%" }}>
                          <PlacesAutocomplete
                            value={text}
                            onChange={(e) => onChangeHandler(e)}
                            onSelect={handleSelect}
                            searchOptions={{
                              types: ["(regions)"],
                              componentRestrictions: { country: "IT" },
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                {/* <div class="input-header">Dove vuoi giocare?</div> */}
                                <div className="d-flex">
                                  <input
                                    style={{
                                      marginTop: "2rem",
                                      width: "100%",
                                      border: "none",
                                    }}
                                    className="form-control"
                                    defaultValue={props.defVal}
                                    {...getInputProps({
                                      placeholder: "Scrivi l'indirizzo",
                                    })}
                                  ></input>
                                  <div
                                    onClick={modalHandler}
                                    style={{
                                      width: "10%",
                                      marginTop: "2.4rem",
                                      paddingLeft: "0.8rem",
                                      backgroundColor: "white",
                                      color: "rgba(167, 24, 86, 1)",
                                      fontWeight: "700",
                                      paddingTop: "0.8rem",
                                      height: "45px",
                                      paddingRight: "0.1rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    X
                                  </div>
                                </div>
                                <div>
                                  {loading ? <div>...loading</div> : null}
                                  <ul className="autoCompItemDisplayer-map">
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active
                                          ? "blue"
                                          : "white",
                                      };
                                      const className = "autoCompleteElement";
                                      return (
                                        <li
                                          onClick={() => {
                                            setText(suggestion.description);
                                            setShouldBeModal(false);
                                          }}
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              style,
                                              className,
                                            }
                                          )}
                                        >
                                          <div className="">
                                            <img
                                              width={20}
                                              src={
                                                process.env.PUBLIC_URL +
                                                "mapPin.png"
                                              }
                                            />
                                            {suggestion.description}
                                          </div>
                                        </li>
                                      );
                                    })}
                                    {suggestionsx.slice(0, 10).map((val) => (
                                      <li
                                        className="autoCompleteElement d-flex"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                          onClickPacchettoHandler(val.ssid);
                                        }}
                                      >
                                        <img
                                          width={"20%"}
                                          src={
                                            val.imgs[0]
                                              ? val.imgs[0].img
                                              : process.env.PUBLIC_URL +
                                                "placeholder.png"
                                          }
                                        />
                                        {val.meta.map((meta) => {
                                          if (
                                            meta.key === "titolo" &&
                                            meta.lang === "it_IT"
                                          ) {
                                            return meta.value;
                                          }
                                        })}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Modal>
                  ) : null}
                </div>
              </div>

              {/* <div><ul className="autoCompItemDisplayer">{suggestionsx.slice(0,10).map((val)=>(<li><AutoCompleteItem onClickItemHandler={onClickItemHandler} text={val.nome_circolo}/></li>))}</ul></div> */}
              {/* <div><ul className="autoCompItemDisplayer">{suggestions.slice(0,10).map((val)=>(<li><AutoCompleteItem text={val.nome_circolo}/></li>))}</ul></div> */}
            </div>
            <div
              className="row d-flex text-center"
              style={{ textAlign: "center" }}
            >
              {" "}
              <div
                className="col-lg-3"
                style={{ width: "43%", marginLeft: "7%" }}
              >
                {" "}
                <DatePicker
                  onChange={(e) => {
                    setData(e);
                  }}
                  value={data}
                  placeholder={"DD/MM/YYYY"}
                  formatStyle="small"
                />
              </div>
              <div className="col-lg-3" style={{ width: "43%" }}>
                {" "}
                <input
                  type="text"
                  readOnly
                  placeholder="Seleziona"
                  value={srcCtx.roomsString}
                  onClick={occupationHandler}
                />
                {shoudlBeOccupation ? (
                  <Occupazione submit={peopleHandler} />
                ) : null}
              </div>
            </div>
          </div>
          <button
            type="submit"
            style={{
              outline: "none",
              width: "100%",
              padding: "15px 0",
              borderColor: "transparent",
              fontWeight: 600,
              color: "white",
              backgroundColor: "#a91354",
              marginBottom: "4rem",
            }}
          >
            Cerca
          </button>
        </form>
      ) : null}
    </div>
  );
}

const Header = (props) => {
  const location = useLocation();
  const [isStandard, setIsStandard] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const [isCircolo, setIsCircolo] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [localCtx, setLocalCtx] = useState("");
  const onClickModal = () => {
    setIsModal(!isModal);
  };
  const authCtx = useContext(AuthContext);
  const srcCtx = useContext(SearchContext);
  const [scdLoading, setScdLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState();
  useEffect(() => {
    setIsLoggedIn(authCtx.isLoggedIn);
  });
  const logOutHandler = () => {
    authCtx.logout();
  };
  const localCtxHandler = (e) => {
    setLocalCtx(e);
  };

  const rederfunction = () => {
    if (location.pathname === "/pacchetti") {
      return <HeaderGolf idCanale={8} />;
    } else if (location.pathname.toLowerCase() === "/campi-da-golf") {
      return <HeaderGolf idCanale={3} />;
    } else if (location.pathname.toLowerCase() === "/gare") {
      return <HeaderGolf idCanale={9} />;
    }
    if (
      location.pathname.toLowerCase() === "/time" ||
      location.pathname.toLowerCase() === "/mappa" ||
      location.pathname.toLowerCase().includes("/prenotazione-campo") ||
      location.pathname.includes("totem")
    ) {
      <></>;
    } else {
      return <StandardHeader logo={props.logo}></StandardHeader>;
    }
  };
  const pacchetti = useGetPacchetti("", "", "");
  const tempTerm = srcCtx.term;
  useEffect(() => {
    localCtxHandler(tempTerm);
  }, [tempTerm]);
  useEffect(() => {}, [localCtx]);

  return (
    // <>
    //   <div id="header">
    //     <div className="container">
    //       <div className="row">
    //         <div className="left-side">
    //           <div id="logo"></div>
    //           <div className="menu-trigger"></div>
    //           <nav className="navigation" className="style-1">
    //             <ul className="header-nav">
    //               <li>
    //                 <Link className="header-link" to={{ pathname: "/" }}>
    //                   Home
    //                 </Link>
    //               </li>
    //               <li>
    //                 {" "}
    //                 <Link
    //                   className="header-link"
    //                   to={{ pathname: "/campi-da-golf" }}
    //                 >
    //                   Campi da golf
    //                 </Link>
    //               </li>
    //               <li>
    //                 <Link
    //                   className="header-link"
    //                   to={{ pathname: "/pacchetti" }}
    //                 >
    //                   Pacchetti
    //                 </Link>
    //               </li>
    //             </ul>
    //           </nav>
    //         </div>
    //         {isLoggedIn && (
    //           <div>
    //             <button onClick={authCtx.logout()}>LOGOUT</button>
    //           </div>
    //         )}
    //         {!isLoggedIn && (
    //           <>
    //             <ModalLogin
    //               isModal={isModal}
    //               onClickModal={onClickModal}
    //             ></ModalLogin>
    //             <div className="right-side">
    //               <button onClick={onClickModal}>Login/Register</button>
    //             </div>
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </>

    <>
      {location.pathname.toLowerCase().includes("/time") ||
      location.pathname.toLowerCase().includes("/struttura") ||
      location.pathname.toLowerCase().includes("/prenotazionestruttura") ||
      location.pathname.toLowerCase().includes("/prenotazione-campo") ||
      location.pathname.toLowerCase().includes("totem") ||
      location.pathname == "/mappa" ? null : (
        <div id="header" className="bg-gradient">
          <div className="version"></div>
          <div className="container  container-fh px-0">
            {rederfunction()}
            {/* {location.pathname != "/pacchetti" ? (
          <StandardHeader
            isModal={isModal}
            onClickModal={onClickModal}
            logout={authCtx.logout}
            isLoggedIn={isLoggedIn}
          ></StandardHeader>
        ) : (
          <SearchdHeader toSearch={pacchetti} preTerm={localCtx} />
        )} */}
          </div>
        </div>
      )}
    </>
  );
};
export default Header;
