import React from "react";
import Gdpr from "../GDPR/Gdpr.jsx";
import { useIntl, FormattedMessage } from "react-intl";
const FormFooter = () => {
  const intl = useIntl();
  const facebook = (
    <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
  );
  const instagram = <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>;
  const linkedin = <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i>;

  const socials = [
    { icon: facebook, link: "https://www.facebook.com/Italybestgolf/" },
    {
      icon: linkedin,
      link: "https://www.linkedin.com/in/italy-best-golf-9426a7167/",
    },
    { icon: instagram, link: "https://www.instagram.com/italybestgolf/" },
  ];

  return (
    <>
      <div className="text-center">
        <h5 className="labelSmall"><FormattedMessage id="seguici" /></h5>
        {socials.map((val) => {
          return (
            <a style={{ color: "white" }} href={val.link} className="m-2">
              {val.icon}
            </a>
          );
        })}

{false ? (<>
        <h5 className="labelSmall"><FormattedMessage id="iscrivitinewsletter" /></h5>
        <text
          style={{
            fontSize: "13px",
            paddingLeft: "15px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          {intl.formatMessage({id:"Per ricevere via e-mail e/o WhatsApp informazioni sulle gare della settimana, sui pacchetti vacanze, sugli eventi e le promozioni Best Golf."})}
        </text>
        <form className="d-flex flex-column formFooter  justify-content-center align-items-center">
          <div className="d-flex">
            <div className="col-6">
              <input placeholder="Nome" type="text" />
            </div>
            <div className="col-6">
              <input placeholder="Cognome" type="text" />
            </div>
          </div>
          <div className="d-flex mt-3">
            <div className="col-6">
              <input placeholder="Telefono" type="text" />
            </div>
            <div className="col-6">
              <input placeholder="Email" type="text" />
            </div>
          </div>
          <div className="col-12 mt-3">
            <Gdpr small={true}>
                <button className="btn btn-success color-white">
                  Iscrivimi
                </button>
            </Gdpr>
          </div>
        </form>
        </>) : null }
      </div>
    </>
  );
};
export default FormFooter;
