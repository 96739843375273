import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import TimeContext from "../store/time-context";
import formatDate from "../function/format-date";
import dateWrapper from "../function/date-wrapper";
import { useIntl, FormattedMessage } from "react-intl";
import useHttp from "../../hooks/use-http";

import CartContext from "../store/cart-context";
const HotelBookingPanel = (props) => {
  const intl = useIntl();
  const timeCtx = useContext(TimeContext);

  const [hotel, setHotel] = useState(null);
  const [hotelStart, setHotelStart] = useState(null);
  const [hotelEnd, setHotelEnd] = useState(null);

  const { cartElements, cartID } = useContext(CartContext);

  const { sendRequest: sendRemoveRequest, isLoading: isRemoveLoading } =
    useHttp();

  useEffect(() => {
    setHotel(null);
    setHotelStart(null);
    setHotelEnd(null);
    timeCtx.hotel.map((val) => {
      console.log("GLI HOTELSSS", val);
      let startHotelDate = dateWrapper(val.data_inizio);
      let endHotelDate = dateWrapper(val.data_fine);

      if (
        props.currentDate >= startHotelDate &&
        endHotelDate > props.currentDate
      ) {
        setHotel(val);
        setHotelStart(formatDate(startHotelDate));
        setHotelEnd(formatDate(endHotelDate));
      } else {
      }
    });
  }, [props.currentDate,timeCtx.hotel,cartElements]);
  if (hotel != null) {
    console.log(
      "CARTO EREMENTO",
      cartElements.find((f) => f.codiceprodotto === hotel.ssid)
    );
  }
  const removeElem = () => {
    if (
      hotel != null &&
      window.confirm("Sei sicuro di voler rimuovere l'hotel?")
    ) {
      const toRem = cartElements.find((f) => f.codiceprodotto === hotel.ssid);
      console.log(toRem.id_elemento);
      sendRemoveRequest({
        url: process.env.REACT_APP_API_ENDPOINT+"/shoppingcart/eliminaelementocarrello",
        methodH: "POST",
        bodyH: {
          id_elemento: `${toRem.id_elemento}`,
        },
        header: {
          Bearer: "T74574H4447J7J",
          "content-type": "application/json",
        },
        manageData: () => {
          console.log("PERDIO");
        },
      }).then(() => {
        window.location.reload(false);
      });
    }
  };

  return (
    <>
      <div className="mb-1">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-md-6 text-uppercase text-center my-1 ">
              <div
                className="time_hotel--panel text-uppercase text-center "
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  props.goToGreenFee();
                }}
              >
                <span>
                  <b>
                    {intl.formatMessage({id:"GreenFeeBTN_Prenota"})}
                    <br />
                    {intl.formatMessage({id:"GreenFeeBTN_GreenFee"})}
                  </b>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center  my-1">
              {hotel ? (
                <>
                  <div className="time_hotel--panel_delete  ">
                   {/* <button className="delete-btn"
                            onClick={removeElem}
                    >
                      X
                    </button>*/}
                    <span className="p-0 m-0 " >
                    <b>{hotel.nomestruttura}</b>
                  </span>
                    <br />
                    <small>
                      IN <b>{hotelStart}</b> OUT <b>{hotelEnd}</b>
                    </small>
                  </div>

                </>
              ) : (
                <div className="time_hotel--panel text-uppercase text-center">
                  <Link
                    to={{
                      pathname: "/time/calendar",
                      currentDate: props.currentDate,
                      currentTime: props.currentTime,
                    }}
                  >
                    <b>
                    {intl.formatMessage({id:"HolidayBTN_Crea"})}
                      <br />
                      {intl.formatMessage({id:"HolidayBTN_Vacanza"})}
                    </b>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HotelBookingPanel;
