import React from "react";
import {Helmet} from 'react-helmet'

const PageHelmet = (props) =>{
    return(
        <>
            <Helmet>
                <title>{props.title.concat(" | Best Golf")}</title>
                <meta name="description" content={props.site_description} />
                {props.children}
            </Helmet>
        </>
    )
}


export default PageHelmet;

