import React, { useState } from "react";
import axios from "axios";
import Classes from "./Modal.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useIntl, FormattedMessage } from "react-intl";
const LoginForm = (props) => {
  const [loginUserName, setLoginUserName] = useState(props.email ? props.email : '');
  console.log(loginUserName);
  const [loginPassword, setLoginPassword] = useState("");
  const [rememberMe, setRememberMe] = useState("");
  const history = useHistory();
  const linkHandler = (url) => {
    history.push({ pathname: url });
    props.close();
  };

  const loginNameHandler = (event) => {
    setLoginUserName(event.target.value);
  };
  const loginPasswordHandler = (event) => {
    setLoginPassword(event.target.value);
  };
  const rememberHandler = (event) => {
    setRememberMe(event.target.checked);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    const user = {
      username: loginUserName,
      password: loginPassword,
      remember: rememberMe,
    };
    props.sendUser(user);
    return false;
  };
  return (
    <>
      <form onSubmit={submitHandler}>
        <div class="mb-3">
          <label class="form-label" for="username">
            Email
          </label>
          <input
            required
            type="text"
            class="form-control"
            id="username"
            value={loginUserName}
            placeholder="Username"
            onChange={loginNameHandler}
          />
        </div>

        <div class="mb-3">
          <div class="d-flex justify-content-end">
            <a href="" class="text-muted">
              <a
                onClick={() => {
                  linkHandler("/lost-password");
                }}
                style={{ color: "#a91354" }}
              >
                <FormattedMessage id="Password dimenticata?" />
              </a>
            </a>
          </div>
          <label class="form-label" for="userpassword">
            Password
          </label>
          <input
            required
            type="password"
            class="form-control"
            id="userpassword"
            placeholder="Password"
            onChange={loginPasswordHandler}
          />
        </div>

        {/* <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="auth-remember-check"
            onChange={rememberHandler}
          />
          <label class="form-check-label" for="auth-remember-check">
            Ricordami
          </label>
        </div>
        <div class="form-check">
          
          <label onClick={props.onClickModal} class="form-check-label" for="auth-remember-check">
          </label>
        </div> */}

        <div class="mt-3 text-end d-flex justify-content-end">
          <button
            class="btn btn-primary w-sm waves-effect waves-light"
            type="submit"
            style={{ width: "100%", backgroundColor: "#a91354" }}
          >
            Log In
          </button>
        </div>
      </form>
    </>
  );
};
export default LoginForm;
