import React from "react";
import { FormattedMessage } from "react-intl";
const Payments = () => {
  const visa = <i class="fab fa-cc-visa fa-2x"></i>;
  const paypal = <i class="fa fa-cc-stripe fa-2x" aria-hidden="true"></i>;
  const mastercard = <i class="fa fa-cc-mastercard fa-2x" aria-hidden="true"></i>;
  const americanExpress = <i class="fa fa-cc-amex fa-2x" aria-hidden="true"></i>;

		const payments=[paypal,visa,mastercard,americanExpress]
  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5 className="labelSmall"><FormattedMessage id="sistemidipagamento" /></h5>
		  <div className="d-flex" style={{gap:"1rem"}}>
		  {payments.map((val)=>(val))}
		  </div>
      </div>
    </>
  );
};
export default Payments;
