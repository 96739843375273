import App from ".";

import reportWebVitals from "./reportWebVitals";
import React, { Suspense, lazy, useState, useEffect, useContext } from "react";
import "./components/style/index.css";

import "./components/style/bootstrap.css";
import "./components/style/bootstrap-grid.css";

import "../node_modules/font-awesome/css/font-awesome.min.css";
import ReactDOM from "react-dom";
import "./components/style/mysass.scss";
import "./components/style/App.css";
import { LastLocationProvider } from "react-router-last-location";

import "./components/style/main.scss";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Wrapper from "./components/common/wrapper";
import AuthContext from "./components/store/auth-context";
import { AuthContextProvider } from "./components/store/auth-context";
import { SearchContextProvider } from "./components/store/search-context";
import { TimeContextProvider } from "./components/store/time-context";
import { Context, IntlProviderWrapper } from "./components/store/intl-context";
import { CartContextProvider } from "./components/store/cart-context";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { initializeFirebase } from "./push-notification";

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const Root = () => {

  const Index = lazy(() => import("./components/Index"));

  const Contatti = lazy(() => import("./components/Contatti/Contatti.jsx"));

  const PacchettoSingolo = lazy(() => import("./components/pacchetto"));

  const Campo = lazy(() => import("./components/campo"));
  const PacchettoDinamico = lazy(() => import("./components/PacchettoDinamico"));
  const PacchettoDinamicoResell = lazy(() => import("./components/PacchettoDinamicoResell"));
  const PacchettoDinamicoPay = lazy(() => import("./components/PacchettoDinamicoPay"));
  

  const Tabellone = lazy(() => import("./components/time/Tabellone"));

  const Gara = lazy(() => import("./components/gara"));
  const BusinessCard = lazy(() => import("./pages/BusinessCard/BusinessCard"));

  const mainB = lazy(() => import("./components/backoffice/mainB"));

  const CampiGolf = lazy(() => import("./components/CampiGolf"));

  const PrenotazioneCampo = lazy(() =>
    import("./components/CampiGolf/PrenotazioneGolf")
  );

  const RiepilogoCampo = lazy(() =>
    import("./components/CampiGolf/RiepilogoGolf")
  );
  const TestPage = lazy(() => import("./components/testPage"));
  const PacketPage = lazy(() => import("./components/PacketPage"));
  const PrenotazionePacchetto = lazy(() =>
    import("./components/common/BookingEngine/FormWizard")
  );
  const DummyAnagrafica = lazy(() => import("./components/DummyAnagrafica"));
  const LostPassword = lazy(() => import("./components/Login/LostPassword"));
  const Homepage = lazy(() => import("./components/Homepage"));

  const TimePage = lazy(() => import("./components/time/TimePage"));
  const TimeCalendarPage = lazy(() =>
    import("./components/time/TimeCalendarPage")
  );
  const TimeServiziPage = lazy(() =>
    import("./components/time/TimeServiziPage")
  );
  const TimeWherePage = lazy(() => import("./components/time/TimeWherePage"));

  const TimeListPage = lazy(() => import("./components/time/TimeListPage"));

  const TimeGolfPage = lazy(() => import("./components/time/TimeGolfPage"));

  const WidgetPage = lazy(() => import("./components/time/WidgetPage"));

  const RiepilogoPage = lazy(() => import("./components/time/RiepilogoPage"));

  const TimeTransfer = lazy(() => import("./components/time/TimeTransfer"));

  const ThankYouCircolo = lazy(()=> import("./components/time/ThankYouCircolo"));
  const ThankYouCheckout = lazy(()=> import("./components/time/ThankYouCheckout"));
  const ThankYouContact = lazy(()=> import("./components/time/ThankYouContact"));

  const AreaRiservata = lazy(() =>
    import("./components/areariservata/AreaRiservata")
  );
  const AreaRiservataEdit = lazy(() =>
    import("./components/areariservata/AreaRiservataEdit")
  );
  const Wip = lazy(() => import("./components/WIP"));

  const StructurePage = lazy(() =>
    import("./components/time/mappa/StructurePage")
  );
  const StructuraPage = lazy(() => import("./components/time/mappa/struttura"));

  const PrenotazioneStruttura = lazy(() =>
    import("./components/time/mappa/PrenotazioneStruttura")
  );

  const Privacy = lazy(() =>
    import("./components/Privacy")
  );

  const BookingEngine = lazy(() =>
    import("./components/common/BookingEngine/FormWizard")
  );
  const RegisterOperator = lazy(() =>
    import("./components/Login/RegisterOperator")
  );
  const EventsRegistration = lazy(() =>
    import("./components/Login/EventsRegistration")
  );
  const RegisterTourOperator = lazy(() =>
    import("./components/Login/RegisterTourOperator")
  );
  const GolfPage = lazy(() => import("./components/CampiGolf/GolfPage"));
  const EsperienzeEsclusive = lazy(() => import("./components/Servizi/EsperienzeEsclusive"));
  const EsperienzeEsclusiveDetail = lazy(() => import("./components/Servizi/EsperienzeEsclusiveDetail"));
  

  
  const { language_cookie: cookie } = useContext(Context);
  const lang = cookie == 'en' ? '/' : cookie;
  
  const carrello = lazy(() => import("./components/carrello"));

  const CalendarioGolf = lazy(() =>
    import("./components/CampiGolf/golfCalendar")
  );
  const Gare = lazy(() => import("./components/gare/GaraPage"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <BrowserRouter basename={lang}>
      <LastLocationProvider>
        <Suspense fallback={<div>LOADING</div>}>
          <Switch>
            <Wrapper>
              <Route exact path="/" component={Homepage} />
              <Route path="/pacchetto/:id" component={PacchettoSingolo} />
              <Route
                path="/prenotazione-pacchetto/:id"
                component={PrenotazionePacchetto}
              />
              <Route exact path="/golf-club/:id" component={Campo} />
              <Route path="/totem/:id" component={Tabellone} />
              <Route path="/gara/:id" component={Gara} />
              <Route path="/calendario-campo/:id" component={CalendarioGolf} />
              <Route
                path="/prenotazione-campo/:slug"
                component={PrenotazioneCampo}
              />
              <Route path="/gare" component={Gare} />
              <Route path="/riepilogo-campo" component={RiepilogoCampo} />
              <Route path="/backoffice" component={mainB} />
              
              <Route path="/campi-da-golf" component={GolfPage} />
              <Route path="/test" component={TestPage} />
              <Route path="/pacchetti" component={PacketPage} />
              <Route path="/dummy-anagrafica" component={DummyAnagrafica} />
              <Route exact path="/lost-password" component={LostPassword} />
              <Route path="/home" component={Homepage} />

              <Route exact path="/privacy" component={Privacy} />
              
              <Route exact path="/esperienze-esclusive" component={EsperienzeEsclusive} />
              <Route exact path="/esperienze-esclusive/:id" component={EsperienzeEsclusiveDetail} />
            
              <Route path="/areariservata" component={AreaRiservata} />
              <Route path="/edit-profile" component={AreaRiservataEdit} />
              
              <Route path="/riepilogocarrello" component={carrello} />
              <Route path="/mappa" component={StructurePage} />
              <Route path="/struttura/:id" component={StructuraPage} />
              <Route
                path="/prenotazionestruttura/:id"
                component={PrenotazioneStruttura}
              />
              <Route path="/wip/:id" component={Wip} />
              <Route exact path="/registraoperatore" component={RegisterOperator} />
              <Route exact path="/registra-tour-operator" component={RegisterTourOperator} />

              <Route path="/events" component={EventsRegistration} />

              <Route path="/contatti" component={Contatti} />

              <Route path="/thank-you-circolo/:id" component={ThankYouCircolo} />
              <Route path="/thank-you-checkout/:id" component={ThankYouCheckout} />

              <Route path="/business-card/:slug" component={BusinessCard} />

              <Route path="/thank-you-contactus" component={ThankYouContact} />
              

              <Route path="/time" component={TimePage}>
                <Route exact path="/time" component={TimePage} />
                <Route path="/time/calendar" component={TimeCalendarPage} />
                <Route path="/time/where" component={TimeWherePage} />
                <Route path="/time/transfer" component={TimeTransfer} />
                <Route path="/time/list" component={TimeListPage} />
                <Route path="/time/checkout" component={RiepilogoPage} />
                <Route path="/time/servizi/:id" component={TimeServiziPage} />
                <Route path="/time/widget/:uuid" component={WidgetPage} />
                <Route path="/time/golf/:slug" component={TimeGolfPage} />
              </Route>


              
              <Route exact path="/circolo/203/:id">
                <Redirect to="/golf-club/versilia-golf-resort-forte-dei-marmi" />
              </Route>
              <Route exact path="/circolo/46/:id">
                <Redirect to="/golf-club/croara-country-club" />
              </Route>
              <Route exact path="/scheda_circolo.php">
                <Redirect to="/golf-club/castelgandolfo-golf-club" />
              </Route>
              <Route exact path="/circolo/189/:id">
                <Redirect to="/golf-club/golf-club-centanni" />
              </Route>
              <Route exact path="/circolo/64/:id">
                <Redirect to="/golf-club/riviera-golf" />
              </Route>
              <Route exact path="/circolo/40/:id">
                <Redirect to="/golf-club/golf-club-fiuggi-1928" />
              </Route>
              <Route exact path="/circolo/59/:id">
                <Redirect to="/golf-club/golf-club-molino-del-pero" />
              </Route>
              <Route exact path="/circolo/38/:id">
                <Redirect to="/golf-club/castelgandolfo-golf-club" />
              </Route>


              <Route exact path="/circolo/126/:id">
                <Redirect to="/golf-club/a-s-golf-club-novara" />
              </Route>
<Route exact path="/circolo/176/:id">
                <Redirect to="/golf-club/acaya-golf-club" />
              </Route>
<Route exact path="/circolo/43/:id">
                <Redirect to="/golf-club/adriatic-golf-club-cervia" />
              </Route>
<Route exact path="/circolo/228/:id">
                <Redirect to="/golf-club/albarella-golf-links" />
              </Route>
<Route exact path="/circolo/217/:id">
                <Redirect to="/golf-club/antognollagolf" />
              </Route>
<Route exact path="/circolo/44/:id">
                <Redirect to="/golf-club/argenta-boscovecchio-golf-club" />
              </Route>
<Route exact path="/circolo/181/:id">
                <Redirect to="/golf-club/argentario-golf-club" />
              </Route>
<Route exact path="/circolo/123/:id">
                <Redirect to="/golf-club/arona-golf-club" />
              </Route>
<Route exact path="/circolo/263/:id">
                <Redirect to="/golf-club/arzaga-golf-club" />
              </Route>
<Route exact path="/circolo/271/:id">
                <Redirect to="/golf-club/asd-golf-acqui-terme" />
              </Route>
<Route exact path="/circolo/226/:id">
                <Redirect to="/golf-club/asolo-golf-club" />
              </Route>
<Route exact path="/circolo/234/:id">
                <Redirect to="/golf-club/associazione-golf-cortina" />
              </Route>
<Route exact path="/circolo/127/:id">
                <Redirect to="/golf-club/associazione-sportiva-golf-club-cherasco" />
              </Route>
<Route exact path="/circolo/177/:id">
                <Redirect to="/golf-club/barialto-golf-club" />
              </Route>
<Route exact path="/circolo/83/:id">
                <Redirect to="/golf-club/barlassina-country-club" />
              </Route>
<Route exact path="/circolo/84/:id">
                <Redirect to="/golf-club/bormio-golf-club" />
              </Route>
<Route exact path="/circolo/290/:id">
                <Redirect to="/golf-club/botanic-golf-sacuba" />
              </Route>
<Route exact path="/circolo/137/:id">
                <Redirect to="/golf-club/boves-golf-club" />
              </Route>
<Route exact path="/circolo/85/:id">
                <Redirect to="/golf-club/brianza-country-club" />
              </Route>
<Route exact path="/circolo/86/:id">
                <Redirect to="/golf-club/ca-degli-ulivi-golf" />
              </Route>
<Route exact path="/circolo/164/:id">
                <Redirect to="/golf-club/cagliari-golf-club" />
              </Route>
<Route exact path="/circolo/50/:id">
                <Redirect to="/golf-club/casalunga-golf-and-resort" />
              </Route>
<Route exact path="/circolo/51/:id">
                <Redirect to="/golf-club/castell-arquato-golf-club" />
              </Route>
<Route exact path="/circolo/87/:id">
                <Redirect to="/golf-club/castello-tolcinasco-golf-resort-spa" />
              </Route>
<Route exact path="/circolo/183/:id">
                <Redirect to="/golf-club/castiglion-del-bosco-golf-club" />
              </Route>
<Route exact path="/circolo/88/:id">
                <Redirect to="/golf-club/chervo-golf-club-san-vigilio" />
              </Route>
<Route exact path="/circolo/76/:id">
                <Redirect to="/golf-club/circolo-club-marigola-lerici" />
              </Route>
<Route exact path="/circolo/154/:id">
                <Redirect to="/golf-club/circolo-del-golf-di-roma-acquasanta" />
              </Route>
<Route exact path="/circolo/89/:id">
                <Redirect to="/golf-club/circolo-di-campagna-di-zoate" />
              </Route>
<Route exact path="/circolo/229/:id">
                <Redirect to="/golf-club/circolo-golf-asiago" />
              </Route>
<Route exact path="/circolo/128/:id">
                <Redirect to="/golf-club/circolo-golf-bogogno" />
              </Route>
<Route exact path="/circolo/80/:id">
                <Redirect to="/golf-club/circolo-golf-degli-ulivi" />
              </Route>
<Route exact path="/circolo/75/:id">
                <Redirect to="/golf-club/circolo-golf-e-tennis-rapallo" />
              </Route>
<Route exact path="/circolo/160/:id">
                <Redirect to="/golf-club/circolo-golf-is-molas" />
              </Route>
<Route exact path="/circolo/174/:id">
                <Redirect to="/golf-club/circolo-golf-napoli" />
              </Route>
<Route exact path="/circolo/130/:id">
                <Redirect to="/golf-club/circolo-golf-torino-la-mandria" />
              </Route>
<Route exact path="/circolo/230/:id">
                <Redirect to="/golf-club/circolo-golf-venezia" />
              </Route>
<Route exact path="/circolo/91/:id">
                <Redirect to="/golf-club/circolo-golf-villa-d-este" />
              </Route>
<Route exact path="/circolo/1/:id">
                <Redirect to="/golf-club/circolo-italy-best-golf" />
              </Route>
<Route exact path="/circolo/218/:id">
                <Redirect to="/golf-club/conero-golf-club" />
              </Route>
<Route exact path="/circolo/255/:id">
                <Redirect to="/golf-club/cortona-golf-spa-resort" />
              </Route>
<Route exact path="/circolo/166/:id">
                <Redirect to="/golf-club/donnafugata-golf-resort-spa" />
              </Route>
<Route exact path="/circolo/184/:id">
                <Redirect to="/golf-club/elba-golf-club-acquabona" />
              </Route>
<Route exact path="/circolo/48/:id">
                <Redirect to="/golf-club/ferrara-golf" />
              </Route>
<Route exact path="/circolo/248/:id">
                <Redirect to="/golf-club/fioranello-golf-club" />
              </Route>
<Route exact path="/circolo/92/:id">
                <Redirect to="/golf-club/gardagolf-country-club" />
              </Route>
<Route exact path="/circolo/74/:id">
                <Redirect to="/golf-club/golf-country-club-castello-di-spessa" />
              </Route>
<Route exact path="/circolo/185/:id">
                <Redirect to="/golf-club/golf-country-club-cosmopolitan" />
              </Route>
<Route exact path="/circolo/63/:id">
                <Redirect to="/golf-club/golf-country-club-riolo-terme-la-torre" />
              </Route>
<Route exact path="/circolo/67/:id">
                <Redirect to="/golf-club/golf-country-club-tarvisio" />
              </Route>
<Route exact path="/circolo/247/:id">
                <Redirect to="/golf-club/golf-club-albisola" />
              </Route>
<Route exact path="/circolo/132/:id">
                <Redirect to="/golf-club/golf-club-alpino-di-stresa" />
              </Route>
<Route exact path="/circolo/204/:id">
                <Redirect to="/golf-club/golf-club-alta-badia" />
              </Route>
<Route exact path="/circolo/95/:id">
                <Redirect to="/golf-club/golf-club-ambrosiano" />
              </Route>
<Route exact path="/circolo/221/:id">
                <Redirect to="/golf-club/golf-club-aosta-arsanieres" />
              </Route>
<Route exact path="/circolo/96/:id">
                <Redirect to="/golf-club/golf-club-aprica-orobie" />
              </Route>
<Route exact path="/circolo/77/:id">
                <Redirect to="/golf-club/golf-club-arenzano" />
              </Route>
<Route exact path="/circolo/53/:id">
                <Redirect to="/golf-club/golf-club-augusto-fava-cento" />
              </Route>
<Route exact path="/circolo/182/:id">
                <Redirect to="/golf-club/golf-club-bellosguardo" />
              </Route>
<Route exact path="/circolo/97/:id">
                <Redirect to="/golf-club/golf-club-bergamo-l-albenza" />
              </Route>
<Route exact path="/circolo/133/:id">
                <Redirect to="/golf-club/golf-club-biella-le-betulle" />
              </Route>
<Route exact path="/circolo/98/:id">
                <Redirect to="/golf-club/golf-club-bogliaco" />
              </Route>
<Route exact path="/circolo/49/:id">
                <Redirect to="/golf-club/golf-club-bologna" />
              </Route>
<Route exact path="/circolo/281/:id">
                <Redirect to="/golf-club/golf-club-ca-daffan" />
              </Route>
<Route exact path="/circolo/231/:id">
                <Redirect to="/golf-club/golf-club-ca-amata" />
              </Route>
<Route exact path="/circolo/232/:id">
                <Redirect to="/golf-club/golf-club-ca-della-nave" />
              </Route>
<Route exact path="/circolo/205/:id">
                <Redirect to="/golf-club/golf-club-campo-carlo-magno" />
              </Route>
<Route exact path="/circolo/68/:id">
                <Redirect to="/golf-club/golf-club-cansiglio" />
              </Route>
<Route exact path="/circolo/215/:id">
                <Redirect to="/golf-club/golf-club-carezza" />
              </Route>
<Route exact path="/circolo/99/:id">
                <Redirect to="/golf-club/golf-club-carimate" />
              </Route>
<Route exact path="/circolo/187/:id">
                <Redirect to="/golf-club/golf-club-casentino" />
              </Route>
<Route exact path="/circolo/69/:id">
                <Redirect to="/golf-club/golf-club-castel-d-aviano" />
              </Route>
<Route exact path="/circolo/134/:id">
                <Redirect to="/golf-club/golf-club-castelconturbia" />
              </Route>
<Route exact path="/circolo/188/:id">
                <Redirect to="/golf-club/golf-club-castelfalfi" />
              </Route>
<Route exact path="/circolo/78/:id">
                <Redirect to="/golf-club/golf-club-castellaro" />
              </Route>
<Route exact path="/circolo/279/:id">
                <Redirect to="/golf-club/golf-club-cavagli" />
              </Route>
<Route exact path="/circolo/269/:id">
                <Redirect to="/golf-club/golf-club-cerrione" />
              </Route>
<Route exact path="/circolo/222/:id">
                <Redirect to="/golf-club/golf-club-cervino" />
              </Route>
<Route exact path="/circolo/268/:id">
                <Redirect to="/golf-club/golf-club-citt-di-asti" />
              </Route>
<Route exact path="/circolo/135/:id">
                <Redirect to="/golf-club/golf-club-claviere" />
              </Route>
<Route exact path="/circolo/233/:id">
                <Redirect to="/golf-club/golf-club-colli-berici" />
              </Route>
<Route exact path="/circolo/136/:id">
                <Redirect to="/golf-club/golf-club-colline-del-gavi" />
              </Route>
<Route exact path="/circolo/145/:id">
                <Redirect to="/golf-club/golf-club-cossato" />
              </Route>
<Route exact path="/circolo/223/:id">
                <Redirect to="/golf-club/golf-club-courmayeur-et-grandes-jorasses" />
              </Route>
<Route exact path="/circolo/141/:id">
                <Redirect to="/golf-club/golf-club-des-iles-borromes" />
              </Route>
<Route exact path="/circolo/206/:id">
                <Redirect to="/golf-club/golf-club-dolomiti" />
              </Route>
<Route exact path="/circolo/138/:id">
                <Redirect to="/golf-club/golf-club-druento-cascina-i-merli" />
              </Route>
<Route exact path="/circolo/288/:id">
                <Redirect to="/golf-club/golf-club-eppan" />
              </Route>
<Route exact path="/circolo/54/:id">
                <Redirect to="/golf-club/golf-club-faenza-le-cigogne" />
              </Route>
<Route exact path="/circolo/278/:id">
                <Redirect to="/golf-club/golf-club-feudo-di-asti" />
              </Route>
<Route exact path="/circolo/207/:id">
                <Redirect to="/golf-club/golf-club-folgaria" />
              </Route>
<Route exact path="/circolo/235/:id">
                <Redirect to="/golf-club/golf-club-frassanelle" />
              </Route>
<Route exact path="/circolo/79/:id">
                <Redirect to="/golf-club/golf-club-garlenda" />
              </Route>
<Route exact path="/circolo/70/:id">
                <Redirect to="/golf-club/golf-club-grado" />
              </Route>
<Route exact path="/circolo/224/:id">
                <Redirect to="/golf-club/golf-club-gressoney-monte-rosa" />
              </Route>
<Route exact path="/circolo/261/:id">
                <Redirect to="/golf-club/golf-club-hermitage" />
              </Route>
<Route exact path="/circolo/274/:id">
                <Redirect to="/golf-club/golf-club-i-ciliegi" />
              </Route>
<Route exact path="/circolo/55/:id">
                <Redirect to="/golf-club/golf-club-i-fiordalisi" />
              </Route>
<Route exact path="/circolo/280/:id">
                <Redirect to="/golf-club/golf-club-i-girasoli" />
              </Route>
<Route exact path="/circolo/285/:id">
                <Redirect to="/golf-club/golf-club-i-salici" />
              </Route>
<Route exact path="/circolo/101/:id">
                <Redirect to="/golf-club/golf-club-il-colombaro" />
              </Route>
<Route exact path="/circolo/104/:id">
                <Redirect to="/golf-club/golf-club-il-torrazzo-cremona" />
              </Route>
<Route exact path="/circolo/236/:id">
                <Redirect to="/golf-club/golf-club-jesolo" />
              </Route>
<Route exact path="/circolo/142/:id">
                <Redirect to="/golf-club/golf-club-la-margherita" />
              </Route>
<Route exact path="/circolo/102/:id">
                <Redirect to="/golf-club/golf-club-la-pinetina" />
              </Route>
<Route exact path="/circolo/103/:id">
                <Redirect to="/golf-club/golf-club-la-rossera" />
              </Route>
<Route exact path="/circolo/143/:id">
                <Redirect to="/golf-club/golf-club-la-serra" />
              </Route>
<Route exact path="/circolo/219/:id">
                <Redirect to="/golf-club/golf-club-lamborghini" />
              </Route>
<Route exact path="/circolo/210/:id">
                <Redirect to="/golf-club/golf-club-lana" />
              </Route>
<Route exact path="/circolo/105/:id">
                <Redirect to="/golf-club/golf-club-lanzo" />
              </Route>
<Route exact path="/circolo/57/:id">
                <Redirect to="/golf-club/golf-club-le-fonti" />
              </Route>
<Route exact path="/circolo/144/:id">
                <Redirect to="/golf-club/golf-club-le-fronde" />
              </Route>
<Route exact path="/circolo/191/:id">
                <Redirect to="/golf-club/golf-club-le-pavoniere" />
              </Route>
<Route exact path="/circolo/106/:id">
                <Redirect to="/golf-club/golf-club-le-robinie" />
              </Route>
<Route exact path="/circolo/107/:id">
                <Redirect to="/golf-club/golf-club-lecco" />
              </Route>
<Route exact path="/circolo/71/:id">
                <Redirect to="/golf-club/golf-club-lignano" />
              </Route>
<Route exact path="/circolo/146/:id">
                <Redirect to="/golf-club/golf-club-margara" />
              </Route>
<Route exact path="/circolo/58/:id">
                <Redirect to="/golf-club/golf-club-matilde-di-canossa" />
              </Route>
<Route exact path="/circolo/108/:id">
                <Redirect to="/golf-club/golf-club-menaggio-cadenabbia" />
              </Route>
<Route exact path="/circolo/109/:id">
                <Redirect to="/golf-club/golf-club-milano" />
              </Route>
<Route exact path="/circolo/211/:id">
                <Redirect to="/golf-club/golf-club-mirabell" />
              </Route>

<Route exact path="/circolo/275/:id">
                <Redirect to="/golf-club/golf-club-moncalieri" />
              </Route>
<Route exact path="/circolo/256/:id">
                <Redirect to="/golf-club/golf-club-monferrato" />
              </Route>
<Route exact path="/circolo/283/:id">
                <Redirect to="/golf-club/golf-club-montebelluna" />
              </Route>
<Route exact path="/circolo/110/:id">
                <Redirect to="/golf-club/golf-club-monticello" />
              </Route>
<Route exact path="/circolo/237/:id">
                <Redirect to="/golf-club/golf-club-padova" />
              </Route>
<Route exact path="/circolo/170/:id">
                <Redirect to="/golf-club/golf-club-palermo-parco-airoldi" />
              </Route>
<Route exact path="/circolo/119/:id">
                <Redirect to="/golf-club/golf-club-paradiso-del-garda" />
              </Route>
<Route exact path="/circolo/157/:id">
                <Redirect to="/golf-club/golf-club-parco-de-medici" />
              </Route>
<Route exact path="/circolo/200/:id">
                <Redirect to="/golf-club/golf-club-parco-di-firenze" />
              </Route>
<Route exact path="/circolo/212/:id">
                <Redirect to="/golf-club/golf-club-passiria-merano-golfclub-passeier" />
              </Route>
<Route exact path="/circolo/220/:id">
                <Redirect to="/golf-club/golf-club-perugia" />
              </Route>
<Route exact path="/circolo/213/:id">
                <Redirect to="/golf-club/golf-club-petersberg" />
              </Route>
<Route exact path="/circolo/161/:id">
                <Redirect to="/golf-club/golf-club-pevero" />
              </Route>
<Route exact path="/circolo/111/:id">
                <Redirect to="/golf-club/golf-club-ponte-di-legno" />
              </Route>
<Route exact path="/circolo/273/:id">
                <Redirect to="/golf-club/golf-club-pragelato" />
              </Route>
<Route exact path="/circolo/192/:id">
                <Redirect to="/golf-club/golf-club-punta-ala" />
              </Route>
<Route exact path="/circolo/162/:id">
                <Redirect to="/golf-club/golf-club-puntalda" />
              </Route>
<Route exact path="/circolo/209/:id">
                <Redirect to="/golf-club/golf-club-pustertal" />
              </Route>
<Route exact path="/circolo/214/:id">
                <Redirect to="/golf-club/golf-club-rendena" />
              </Route>
<Route exact path="/circolo/284/:id">
                <Redirect to="/golf-club/golf-club-rovigo" />
              </Route>
<Route exact path="/circolo/265/:id">
                <Redirect to="/golf-club/golf-club-salerno" />
              </Route>
<Route exact path="/circolo/260/:id">
                <Redirect to="/golf-club/golf-club-saluzzo" />
              </Route>
<Route exact path="/circolo/149/:id">
                <Redirect to="/golf-club/golf-club-san-giovanni-dei-boschi" />
              </Route>
<Route exact path="/circolo/173/:id">
                <Redirect to="/golf-club/golf-club-san-michele" />
              </Route>
<Route exact path="/circolo/193/:id">
                <Redirect to="/golf-club/golf-club-san-miniato" />
              </Route>
<Route exact path="/circolo/81/:id">
                <Redirect to="/golf-club/golf-club-sant-anna" />
              </Route>
<Route exact path="/circolo/276/:id">
                <Redirect to="/golf-club/golf-club-santa-maria-maggiore" />
              </Route>
<Route exact path="/circolo/60/:id">
                <Redirect to="/golf-club/golf-club-santo-stefano" />
              </Route>
<Route exact path="/circolo/286/:id">
                <Redirect to="/golf-club/golf-club-sappada" />
              </Route>
<Route exact path="/circolo/150/:id">
                <Redirect to="/golf-club/golf-club-sestrieres" />
              </Route>
<Route exact path="/circolo/272/:id">
                <Redirect to="/golf-club/golf-club-stupinigi" />
              </Route>
<Route exact path="/circolo/239/:id">
                <Redirect to="/golf-club/golf-club-terme-di-galzignano" />
              </Route>
<Route exact path="/circolo/194/:id">
                <Redirect to="/golf-club/golf-club-tirrenia" />
              </Route>
<Route exact path="/circolo/195/:id">
                <Redirect to="/golf-club/golf-club-toscana-il-pelagone" />
              </Route>
<Route exact path="/circolo/72/:id">
                <Redirect to="/golf-club/golf-club-trieste" />
              </Route>
<Route exact path="/circolo/73/:id">
                <Redirect to="/golf-club/golf-club-udine" />
              </Route>
<Route exact path="/circolo/196/:id">
                <Redirect to="/golf-club/golf-club-ugolino" />
              </Route>
<Route exact path="/circolo/277/:id">
                <Redirect to="/golf-club/golf-club-valcurone" />
              </Route>
<Route exact path="/circolo/112/:id">
                <Redirect to="/golf-club/golf-club-varese" />
              </Route>
<Route exact path="/circolo/240/:id">
                <Redirect to="/golf-club/golf-club-vicenza" />
              </Route>
<Route exact path="/circolo/152/:id">
                <Redirect to="/golf-club/golf-club-villa-carolina" />
              </Route>
<Route exact path="/circolo/241/:id">
                <Redirect to="/golf-club/golf-club-villa-condulmer" />
              </Route>
<Route exact path="/circolo/287/:id">
                <Redirect to="/golf-club/golf-club-villa-giusti" />
              </Route>
<Route exact path="/circolo/122/:id">
                <Redirect to="/golf-club/golf-club-villa-paradiso" />
              </Route>
<Route exact path="/circolo/249/:id">
                <Redirect to="/golf-club/golf-club-villafranca-le-vigne" />
              </Route>
<Route exact path="/circolo/282/:id">
                <Redirect to="/golf-club/golf-club-zerman" />
              </Route>
<Route exact path="/circolo/94/:id">
                <Redirect to="/golf-club/golf-continental-verbania" />
              </Route>
<Route exact path="/circolo/100/:id">
                <Redirect to="/golf-club/golf-crema-resort" />
              </Route>
<Route exact path="/circolo/114/:id">
                <Redirect to="/golf-club/golf-dei-laghi" />
              </Route>
<Route exact path="/circolo/56/:id">
                <Redirect to="/golf-club/golf-del-ducato" />
              </Route>
<Route exact path="/circolo/242/:id">
                <Redirect to="/golf-club/golf-della-montecchia" />
              </Route>
<Route exact path="/circolo/115/:id">
                <Redirect to="/golf-club/golf-di-franciacorta" />
              </Route>
<Route exact path="/circolo/116/:id">
                <Redirect to="/golf-club/golf-green-club-lainate" />
              </Route>
<Route exact path="/circolo/258/:id">
                <Redirect to="/golf-club/golf-le-primule" />
              </Route>
<Route exact path="/circolo/117/:id">
                <Redirect to="/golf-club/golf-le-rovedine" />
              </Route>
<Route exact path="/circolo/90/:id">
                <Redirect to="/golf-club/golf-premeno" />
              </Route>
<Route exact path="/circolo/65/:id">
                <Redirect to="/golf-club/golf-salsomaggiore-terme" />
              </Route>
<Route exact path="/circolo/259/:id">
                <Redirect to="/golf-club/golf-san-vito" />
              </Route>
<Route exact path="/circolo/113/:id">
                <Redirect to="/golf-club/golf-vigevano" />
              </Route>
<Route exact path="/circolo/208/:id">
                <Redirect to="/golf-club/golfclub-st-vigil-seis" />
              </Route>
<Route exact path="/circolo/250/:id">
                <Redirect to="/golf-club/i-monasteri-golf-resort" />
              </Route>
<Route exact path="/circolo/167/:id">
                <Redirect to="/golf-club/il-picciolo-golf-club" />
              </Route>
<Route exact path="/circolo/163/:id">
                <Redirect to="/golf-club/is-arenas-golf-country-club" />
              </Route>
<Route exact path="/circolo/251/:id">
                <Redirect to="/golf-club/le-saie-golf-club" />
              </Route>
<Route exact path="/circolo/37/:id">
                <Redirect to="/golf-club/marco-simone-golf-club" />
              </Route>
<Route exact path="/circolo/252/:id">
                <Redirect to="/golf-club/mare-di-roma-golf-club" />
              </Route>
<Route exact path="/circolo/197/:id">
                <Redirect to="/golf-club/maremma-golf-club" />
              </Route>
<Route exact path="/circolo/178/:id">
                <Redirect to="/golf-club/metaponto-golf-club" />
              </Route>
<Route exact path="/circolo/172/:id">
                <Redirect to="/golf-club/miglianico-golf-country-club" />
              </Route>
<Route exact path="/circolo/264/:id">
                <Redirect to="/golf-club/mirabella-golf-club" />
              </Route>
<Route exact path="/circolo/61/:id">
                <Redirect to="/golf-club/modena-golf-country-club" />
              </Route>
<Route exact path="/circolo/118/:id">
                <Redirect to="/golf-club/molinetto-country-club" />
              </Route>
<Route exact path="/circolo/198/:id">
                <Redirect to="/golf-club/montecatini-golf-club" />
              </Route>
<Route exact path="/circolo/124/:id">
                <Redirect to="/golf-club/monteforte-golf-club-delle-langhe" />
              </Route>
<Route exact path="/circolo/199/:id">
                <Redirect to="/golf-club/montelupo-golf-club" />
              </Route>
<Route exact path="/circolo/156/:id">
                <Redirect to="/golf-club/nazionale-golf-club" />
              </Route>
<Route exact path="/circolo/158/:id">
                <Redirect to="/golf-club/olgiata-golf-club" />
              </Route>
<Route exact path="/circolo/289/:id">
                <Redirect to="/golf-club/panorama-golf-varese" />
              </Route>
<Route exact path="/circolo/159/:id">
                <Redirect to="/golf-club/parco-di-roma-golf-club" />
              </Route>
<Route exact path="/circolo/186/:id">
                <Redirect to="/golf-club/poggio-dei-medici-golf-club" />
              </Route>
<Route exact path="/circolo/238/:id">
                <Redirect to="/golf-club/pra-delle-torri-golf-caorle" />
              </Route>
<Route exact path="/circolo/257/:id">
                <Redirect to="/golf-club/reggio-emilia-golf" />
              </Route>
<Route exact path="/circolo/190/:id">
                <Redirect to="/golf-club/royal-golf-club-la-bagnaia" />
              </Route>
<Route exact path="/circolo/148/:id">
                <Redirect to="/golf-club/royal-park-i-roveri" />
              </Route>
<Route exact path="/circolo/120/:id">
                <Redirect to="/golf-club/salice-terme-golf-country" />
              </Route>
<Route exact path="/circolo/180/:id">
                <Redirect to="/golf-club/san-domenico-golf" />
              </Route>
<Route exact path="/circolo/66/:id">
                <Redirect to="/golf-club/san-valentino-golf-club" />
              </Route>
<Route exact path="/circolo/267/:id">
                <Redirect to="/golf-club/serravalle-golf-club" />
              </Route>
<Route exact path="/circolo/165/:id">
                <Redirect to="/golf-club/tanka-golf-villasimius" />
              </Route>
<Route exact path="/circolo/262/:id">
                <Redirect to="/golf-club/tarquinia-country-club" />
              </Route>
<Route exact path="/circolo/201/:id">
                <Redirect to="/golf-club/terme-di-saturnia-golf-club" />
              </Route>
<Route exact path="/circolo/155/:id">
                <Redirect to="/golf-club/terre-dei-consoli" />
              </Route>
<Route exact path="/circolo/216/:id">
                <Redirect to="/golf-club/tesino-golf-club-la-farfalla" />
              </Route>
<Route exact path="/circolo/254/:id">
                <Redirect to="/golf-club/tiber-golf-club" />
              </Route>
<Route exact path="/circolo/270/:id">
                <Redirect to="/golf-club/torre-dei-ronchi" />
              </Route>
<Route exact path="/circolo/202/:id">
                <Redirect to="/golf-club/valdichiana-golf-club" />
              </Route>
<Route exact path="/circolo/121/:id">
                <Redirect to="/golf-club/valtellina-golf-club" />
              </Route>
<Route exact path="/circolo/171/:id">
                <Redirect to="/golf-club/verdura-golf-spa-resort" />
              </Route>
<Route exact path="/circolo/246/:id">
                <Redirect to="/golf-club/verona-golf-club" />
              </Route>
<Route exact path="/circolo/62/:id">
                <Redirect to="/golf-club/verucchio-golf-club" />
              </Route>
<Route exact path="/circolo/175/:id">
                <Redirect to="/golf-club/volturno-golf" />
              </Route>

              <Route exact path="/pacchetto-dinamico/:id" component={PacchettoDinamico} />
              <Route exact path="/paga-adesione/:uuid" component={PacchettoDinamicoPay} />

              <Route exact path="/worldam" component={PacchettoDinamico} />

              <Route exact path="/dynamic-package/re/:id" component={PacchettoDinamicoResell} />

              <Route exact path="/dynamic-package/:id" component={PacchettoDinamico} />
              <Route exact path="/pay-package/:uuid" component={PacchettoDinamicoPay} />
              


              


            </Wrapper>
          </Switch>
        </Suspense>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <AuthContextProvider>
    <SearchContextProvider>
      <CartContextProvider>
        <TimeContextProvider>
          <IntlProviderWrapper locale="it">
            <Root />
          </IntlProviderWrapper>
        </TimeContextProvider>
      </CartContextProvider>
    </SearchContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
// initializeFirebase();

serviceWorkerRegistration.unregister();
if (caches) {
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName);
    });
  }).then(() => {
    serviceWorkerRegistration.register();
  })
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
