import React, { useEffect, useState } from "react";

const useGetAllGare = () => {
  const [gare, setGare] = useState([]);
  useEffect(() => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: process.env.REACT_APP_API_ENDPOINT+"/golf/gare",
      headers: {
        Accept: "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log("USE GET ALL GARE",response.data);
			  setGare(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return gare;
};

export default useGetAllGare;
