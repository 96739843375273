import React from "react";
import ModalLogin from "../Login/ModalLogin";
import AuthContext from "../store/auth-context";
import { useContext } from "react";
import { Drawer } from "@mui/material";
import { useState } from "react";
import { ListItemText } from "@mui/material";
import { ListItem } from "@mui/material";
import { List } from "@mui/material";
import { useHistory } from "react-router";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import iconuser from "../WrappedLogin/Sample_User_Icon.svg";
import { useIntl, FormattedMessage } from "react-intl";


import CartHeader from "../CartHeader/CartHeader";
const WrappedLogin = (props) => {
    const intl = useIntl();
		const history=useHistory()
		const authCtx=useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(false);
  const openHandler = () => {
    setIsOpen((old) => !old);
  };
  const facebook = (
    <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
  );
  const instagram = <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>;
  const linkedin = <i class="fa fa-linkedin fa-2x" aria-hidden="true"></i>;
  const socials = [
    { icon: facebook, link: "https://www.facebook.com/Italybestgolf/" },
    {
      icon: linkedin,
      link: "https://www.linkedin.com/in/italy-best-golf-9426a7167/",
    },
    {
      icon: instagram,
      link: "https://www.instagram.com/p/Bl2sPH2B-rM/?utm_source=ig_share_sheet&igshid=1dg5hphrkl3ht&r=wa1",
    },
  ];
  return (
    <>
      <div className=" justify-content-center align-items-center d-flex  ">
        {/* <div className="box box1 hide-mobile">
            <img src={process.env.PUBLIC_URL + "icona-search.png"} alt="" />
          </div>
          <div className="box box2 hide-mobile">
            <span>IT</span>
            <img src={process.env.PUBLIC_URL + "bandiera-italia.png"} alt="" />
          </div> */}
        {!authCtx.isLoggedIn && (
          <>
            <div className="box box3">
              <ModalLogin
                classcustom=""
                testocustom={intl.formatMessage({id:"Accedi "})}
                mode={1}
              ></ModalLogin>
            </div>
          </>
        )}
        {authCtx.isLoggedIn && (
          <div className="box  box3">
            <Drawer onClose={openHandler} open={isOpen} anchor={"right"}>
              <List>
                <div
                  className={
                    "img-profile d-flex justify-content-center align-items-center bg-gradient"
                  }
                >
                  <img
                    src="/logo1.png"
                    alt=""
                    className={"img-fluid pb-3 pt-3  "}
                  />
                </div>
                <Divider />
                <ListItem>
                  <ListItemText className="color-primary text-uppercase">
                    {intl.formatMessage({id:"Benvenuto"})} {authCtx.name}
                  </ListItemText>
                </ListItem>
                <Divider />
                <ListItem button>
                  <ListItemText
                    onClick={() => {
                      history.push({ pathname: "/areariservata" });
                      openHandler();
                    }}
                    primary={"PROFILO"}
                  />
                </ListItem>
                <Divider />

                <ListItem button>
                  <ListItemText
                    onClick={() => {
                      authCtx.logout();
                      history.push('/');
                      history.go(0);
                     
                    }}
                    primary={"LOGOUT"}
                  />
                </ListItem>
                <Divider />

                <p className="text-center pt-3">
                  {socials.map((val) => {
                    return (
                      <a
                        style={{ color: "color-primary" }}
                        href={val.link}
                        className="m-2"
                      >
                        {val.icon}
                      </a>
                    );
                  })}
                </p>
              </List>
            </Drawer>





            <span className="span d-none d-md-block text-small"
                  style={{ cursor: "pointer" }}
                  onClick={openHandler}>
               {intl.formatMessage({id:"ciao"})} {authCtx.name}
               <img src={iconuser} alt="" />
            </span>
            <span className="span d-block d-md-none text-small"
                  style={{ cursor: "pointer" }}
                  onClick={openHandler}>

               <img src={iconuser} alt="" className="icon-header" />
            </span>



          </div>
        )}
        {props.isLoggedIn && (
          <div>
            <button onClick={props.logout}>LOGOUT</button>
            <Link
              className="header-link"
              to={{
                pathname: "/areariservata",
              }}
            >
              Area riservata
            </Link>
          </div>
        )}

        {/* <button onClick={onClickModal}>Login/Register</button>  */}
		  <CartHeader/>
      </div>
    </>
  );
};
export default WrappedLogin
