import React, { useState } from "react";

const TimeContext = React.createContext({
  selectedStartDate: "",
  selectedEndDate: "",
  selectedTime: "",
  currentDay: "",
  currentService: "",
  hotel: [],
  widget: false,
  widgetParameters: {
    strutture: [],
    circoli: [],
    tematismi: [],
    servizituristici: [],
    range: 50,
    lat: 16.0,
    lon: 42.0,
  },
  widgetUrl: "",

  sendselectedTime: (value) => {},
  sendWidget: (value) => {},
  sendWidgetParameters: (value) => {},
  sendselectedStartDate: (value) => {},
  sendselectedEndDate: (value) => {},
  sendcurrentDay: (value) => {},
  sendcurrentService: (value) => {},
  sendHotel: (value) => {},
  removeHotel: (id) => {},
});

export const TimeContextProvider = (props) => {
  const [selectedTime, setselectedTime] = useState(0);
  const [selectedStartDate, setselectedStartDate] = useState(0);
  const [selectedEndDate, setselectedEndDate] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  const [currentService, setCurrentService] = useState(0);
  const [hotel, setHotel] = useState([]);
  const [widget, setWidget] = useState(false);
  const [widgetUrl, setWidgetUrl] = useState("");
  const [widgetParameters, setWidgetParameters] = useState({
    strutture: [],
    circoli: [],
    tematismi: [],
    servizituristici: [],
    range: 50,
    lat: 16.0,
    lon: 42.0,
  });

  const removeHotel = (id) => {
    console.log("ID CHE PASSO AL CONTEXT", id);
    setHotel((old) => old.filter((fil) => fil.ssid != id));
  };

  const selectedTimeHandler = (val) => {
    setselectedTime(val);
  };

  const selectedStartDateHandler = (val) => {
    setselectedStartDate(val);
  };

  const selectedEndDateHandler = (val) => {
    setselectedEndDate(val);
  };

  const currentDayHandler = (val) => {
    setCurrentDay(val);
  };

  const currentServiceHandler = (val) => {
    setCurrentService(val);
  };

  const widgetHandler = (val) => {
    setWidget(val);
  };

  const widgetParametersHandler = (val) => {
    setWidgetParameters(val);
  };

  const hotelHandler = (val) => {
    const newState = [...hotel];
    newState.push(val);
    console.log("HANDLER");
    console.log(newState);
    setHotel(newState);
  };
  const urlHandler = (url) => {
    setWidgetUrl(url);
  };

  // const widgetParametersHandler=(val)=>{
  //     console.log("PARAMETRI WIDGET",val)
  //     setWidgetParameters(val)
  // }

  const contextValue = {
    selectedTime: selectedTime,
    selectedStartDate: selectedStartDate,
    selectedEndDate: selectedEndDate,
    currentDay: currentDay,
    currentService: currentService,
    hotel: hotel,
    widget: widget,
    widgetParameters: widgetParameters,
    widgetUrl,

    sendselectedTime: selectedTimeHandler,
    sendselectedStartDate: selectedStartDateHandler,
    sendselectedEndDate: selectedEndDateHandler,
    sendcurrentDay: currentDayHandler,
    sendcurrentService: currentServiceHandler,
    sendHotel: hotelHandler,
    sendWidget: widgetHandler,
    sendWidgetParameters: widgetParametersHandler,
    removeHotel: removeHotel,
    urlHandler,
  };

  return (
    <TimeContext.Provider value={contextValue}>
      {props.children}
    </TimeContext.Provider>
  );
};
export default TimeContext;
