import React, { useState, useEffect } from "react";

const SearchContext = React.createContext({
  term: "",
  data: "",
  data_out: "",
  data_in: "",
  data_array: [],
  numbers: "",
  lat: "",
  lon: "",
  roomsString: "",
  rooms: [],
  roomsQuery: "",
  buttonValue: "",
  players: "",
  sendPlayers: (value) => {},
  sendLat: (value) => {},
  sendLon: (value) => {},
  sendData: (value) => {},
  sendNumbers: (value) => {},
  send: (value) => {},
  clear: (value) => {},
  sendRoomsString: (value) => {},
  sendRooms: (value) => {},
  sendRoomsQuery: (value) => {},
  sendDataOut: (value) => {},
  sendDataArray: (value) => {},
  sendButtonValue: (value) => {},
});

export const SearchContextProvider = (props) => {
		const datax=new Date().toISOString().substring(0,10);

  const [term, setTerm] = useState("");
  const [data, setData] = useState(datax);
  const [dataOut, setDataOut] = useState("");
  const [numbers, setNumbers] = useState("");
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const [roomsString, setRoomsString] = useState("");
  const [rooms, setRooms] = useState([]);
  const [roomsQuery, setRoomsQuery] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [dataIn, setDataIn] = useState("");
  const [buttonValue, setButtonValue] = useState("Golf");
  const [players, setPlayers] = useState(1);
  const sendPlayers = (play) => {
    console.log("I PLAYERS DEL PROVIDER");
    setPlayers(play);
  };
  const sendLatHandler = (lati) => {
    setLat(lati);
    console.log("IL LAT DELL'HANDLER", lati);
  };
  const sendLonHandler = (long) => {
    setLon(long);
    console.log("IL LON DELL'HANDLER", long);
  };
  const sendHandler = (term) => {
    setTerm(term);
    console.log("IL TERM DEL PROVIDER", term);
  };
  const sendNumbersHandler = (number) => {
    setNumbers(number);
    console.log("IL NUMBER DEL PROVIDER", number);
  };
  const sendDataHandler = (data) => {
    setData(data);
    console.log("LA DATA DEL PROVIDER", data);
  };
  const clearHandler = () => {
    setLat();
    setLon();
  };
  const sendRoomsStringHandler = (string) => {
    setRoomsString(string);
    console.log("la Stringa del provider", string);
  };
  const sendRoomsHandler = (roomsx) => {
    setRooms(roomsx);
  };
  const sendRoomsQueryHandler = (roomsx) => {
    setRoomsQuery(roomsx);
    console.log("ROOMS QUERY PROVIDER", roomsx);
  };
  const sendDataOutHandler = (dataOutx) => {
    console.log("LA DATA OUT DEL PROVIDER", dataOutx);
    setDataOut(dataOutx);
  };
  const sendDataArrayHandler = (dataArrayx) => {
    console.log("L'array data del provider", dataArrayx);
    setDataArray(dataArrayx);
  };
  useEffect(() => {
    setDataIn(dataArray[0]);
    setDataOut(dataArray[0]);
  }, [dataArray]);
  const buttonValueHandler = (buttonVal) => {
    console.log("il button value del context", buttonVal);
    setButtonValue(buttonVal);
  };
  const contextValue = {
    term: term,
    send: sendHandler,
    numbers: numbers,
    sendNumbers: sendNumbersHandler,
    data: data,
    sendData: sendDataHandler,
    lat: lat,
    sendLat: sendLatHandler,
    sendLon: sendLonHandler,
    lon: lon,
    clear: clearHandler,
    roomsString: roomsString,
    sendRoomsString: sendRoomsStringHandler,
    rooms: rooms,
    sendRooms: sendRoomsHandler,
    roomsQuery: roomsQuery,
    sendRoomsQuery: sendRoomsQueryHandler,
    data_out: dataOut,
    sendDataOut: sendDataOutHandler,
    sendDataArray: sendDataArrayHandler,
    data_array: dataArray,
    buttonValue: buttonValue,
    sendButtonValue: buttonValueHandler,
		  players:players,
		  sendPlayers:sendPlayers,
  };

  return (
    <SearchContext.Provider value={contextValue}>
      {props.children}
    </SearchContext.Provider>
  );
};
export default SearchContext;
