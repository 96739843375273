import { Context } from "../../store/intl-context";
import { useContext, useState } from "react";
import { useHistory } from 'react-router'; 
import { useEffect } from "react";

const LanguageSelector = (props) => {
  const { language_cookie: cookie, setCookie } = useContext(Context);
  const history = useHistory();

  const [enLink, setEnLink] = useState(window.location.pathname.replace(`/${cookie}`, ''));
  const [itLink, setItLink] = useState(`/it${window.location.pathname.replace(`/${cookie}`, '')}`);
  const [deLink, setDeLink] = useState(`/de${window.location.pathname.replace(`/${cookie}`, '')}`);


  useEffect(()=>{ 

    setEnLink(window.location.pathname.replace(`/${cookie}`, ''));
    setItLink(`/it${window.location.pathname.replace(`/${cookie}`, '')}`);
    setDeLink(`/de${window.location.pathname.replace(`/${cookie}`, '')}`);
    
  }, [window.location.pathname, props, cookie]);

  return (
    <div style={{ gap: "1rem" }} className="d-flex flex-row">
      <div
        
      >
        <a href={itLink} style={{ color:"#FFF", cursor: "pointer", fontWeight: cookie === "it" ? 700 : 300 }}>
        IT 
        </a>
      </div>
      <div
      >
        <a href={enLink} style={{ color:"#FFF", cursor: "pointer", fontWeight: cookie === "en" ? 700 : 300 }}>
        EN
        </a>
      </div>
      <div
      >
        <a href={deLink} style={{ color:"#FFF", cursor: "pointer", fontWeight: cookie === "de" ? 700 : 300 }}>
        DE
        </a>
      </div>
    </div>
  );
};
export default LanguageSelector;
