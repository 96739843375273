import React, { useContext } from "react";
import { useState, useEffect } from "react";
import SearchContext from "../../store/search-context";
import Modal from "../../Login/Modal";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory } from "react-router";
const AdvancedTextSearch = (props) => {
  const [text, setText] = useState("");
  const [shouldBeOpen, setShouldBeOpen] = useState(false);
  const [suggestionsx, setSuggestionsx] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const srcCtx = useContext(SearchContext);
  //! ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const textHandler = (e) => {
    setText(e.target.value);
  };
  const submitPlaceCallBack = async (event) => {
    const results = await geocodeByAddress(event);

    const latLng = await getLatLng(results[0]);
    console.log("LAT E LONG", latLng);
    if (!props.header) {
      const dataSend = new Date();
      const dataSendString = dataSend.toISOString();
      console.log("LE DATE DI DIOZ", dataSend, dataSendString);
      srcCtx.send(event);
      history.push({
        pathname: "/campi-da-golf",
        state: {
          lat: latLng.lat,
          lon: latLng.lng,
          datain: dataSendString,
          dataout: dataSendString,
        },
      });
    }
    if (props.header === true) {
      props.liftLat(latLng.lat);
      props.liftLon(latLng.lng);
			setSuggestionsx([])
    }
  };
  const shouldBeOpenHandler = () => {
    setShouldBeOpen(!shouldBeOpen);
  };
  const handleSelect = async (value) => {
    // const results = await geocodeByAddress(value);

    // const latLng = await getLatLng(results[0]);
    if (props.header === true) {
      props.onPick(value);
    }
    setText(value);
    submitPlaceCallBack(value);
  };
  const onClickPacchettoHandler = (url) => {
    history.replace(`golf-club/${url}`);
    setSuggestionsx([]);
  };
  const onChangeHandler = (text) => {
    let matches = [];
    if (text != "") {
      matches = props.toSearch.filter((golf) => {
        if (golf.nome_circolo.toLowerCase().includes(text.toLowerCase())) {
          return golf;
        }
      });
    }
    console.log("TOSEARCH", matches);
    setSuggestionsx(matches);
    console.log("TEXT", text);
    setText(text);
  };
  useEffect(() => {
    console.log("TI PREEEEEEEEEEEEEEEEEEEEEEEEEGO", suggestionsx);
    // console.log(props.toSearch.map((val)=>{
    //   val.elementi.some((s)=>{
    //   if(s.nome!=undefined){
    //     console.log("Ssss",s.nome)
    //   }
    //   })
    // }))
  }, [suggestionsx]);
  // useEffect(() => {
  //   props.toSearch.filter((val) => {
  //     // val.meta.filter((val) => {
  //     //   console.log(
  //     //     val.key === "titolo" &&
  //     //       val.lang === "it_IT" &&
  //     //       val.value.toLowerCase().includes("test")
  //     //   );
  //     // });
  //    console.log("SOME",val.meta.some((val)=>(val==='titolo') ))
  //   });
  //   setIsLoading(true);
  // }, [props]);
  //! ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  if (props.mobile) {
    return (
      <>
        <div
          style={{ marginLeft: "0rem", marginRight: "0rem" }}
          className="d-flex justify-content-center align-items-center"
        >
          {props.mode === "home" ? (
            <div
              className="col-lg-6 bg-color-our-color"
              style={{
                color: "white",
                borderRadius: 5,
                padding: "0.8rem",
                fontWeight: 700,
              }}
              onClick={shouldBeOpenHandler}
            >
              Dove vuoi giocare?
            </div>
          ) : (
            <input
              type="text"
              placeholder="Dove vuoi giocare?"
              className="form-control"
              value={text}
              readOnly
              style={{
                maxWidth: "800px",
                borderRadius: "7px",
                backgroundColor: "white",
              }}
              onClick={shouldBeOpenHandler}
            />
          )}

          {shouldBeOpen ? (
            <Modal>
              <div className="show-overlay">
                <div className="card " style={{ height: "100%" }}>
                  <PlacesAutocomplete
                    value={text}
                    ref={(a) => {
                      if (!a) return;
                      a.handleInputOnBlur = () => {};
                    }}
                    onChange={(e) => onChangeHandler(e)}
                    onSelect={handleSelect}
                    searchOptions={{
                      types: ["(regions)"],
                      componentRestrictions: { country: "IT" },
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="ml-5 mt-5">
                        <div class="input-header mt-3">Dove vuoi giocare?</div>
                        <div className="d-flex">
                          <input
                            
                            style={{
                              width: "100%",
                              border: "none",
                            }}
                            className="form-control"
                            defaultValue={props.defVal}
                            {...getInputProps({
                              placeholder: "Scegli la zona o il campo da Golf",
                            })}
                          ></input>
                          <div
                            onClick={shouldBeOpenHandler}
                            style={{
                              width: "10%",
                              marginTop: "0.4rem",
                              paddingLeft: "0.8rem",
                              backgroundColor: "white",
                              color: "rgba(167, 24, 86, 1)",
                              fontWeight: "700",
                              paddingTop: "0.8rem",
                              height: "45px",
                              paddingRight: "0.1rem",
                            }}
                          >
                            X
                          </div>
                        </div>
                        <div>
                          {loading ? <div>...loading</div> : null}
                          <ul className="autoCompItemDisplayer-map">
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "blue"
                                  : "white",
                              };
                              const className = "autoCompleteElement";
                              console.log("suggestions", suggestion);
                              return (
                                <li
                                  onClick={() => {
                                    submitPlaceCallBack(suggestion.description);
                                  }}
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                    className,
                                  })}
                                >
                                  <div className="">
                                    <img
                                      width={20}
                                      src={
                                        process.env.PUBLIC_URL + "mapPin.png"
                                      }
                                    />
                                    {suggestion.description}
                                  </div>
                                </li>
                              );
                            })}
                            {suggestionsx.slice(0, 3).map((val) => (
                              <li
                                className="autoCompleteElement d-flex"
                                style={{
                                  width: "100%",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                }}
                                onClick={() => {
                                  onClickPacchettoHandler(val.slug);
                                }}
                              >
                                <img
                                  width={"20%"}
                                  src={
                                    val.images[0]
                                      ? val.images[0].img
                                      : process.env.PUBLIC_URL +
                                        "placeholder.png"
                                  }
                                />
                                {val.nome_circolo}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      </>
    );
  } else {
    return (
      <div>
        {" "}
        <PlacesAutocomplete
          value={text}
          ref={(a) => {
            if (!a) return;
            a.handleInputOnBlur = () => {};
          }}
          onChange={(e) => onChangeHandler(e)}
          onSelect={handleSelect}
          searchOptions={{
            types: ["(regions)"],
            componentRestrictions: { country: "IT" },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className=" mt-5">
              <div className="d-flex align-items-center justify-content-center">
                <input
                  
                  style={{
                    maxWidth: 1000,
                    border: "none",
                  }}
                  className="form-control"
                  defaultValue={props.defVal}
                  {...getInputProps({
                    placeholder: "Scegli la zona o il campo da Golf",
                  })}
                ></input>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                {loading ? <div>...loading</div> : null}
                <ul className="autoCompItemDisplayer-map">
                  {suggestions.slice(0, 3).map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "blue" : "white",
                    };
                    const className = "autoCompleteElement";
                    console.log("suggestions", suggestion);
                    return (
                      <li
                        onClick={() => {
                          submitPlaceCallBack(suggestion.description);
                        }}
                        {...getSuggestionItemProps(suggestion, {
                          style,
                          className,
                        })}
                      >
                        <div className="">
                          <img
                            width={20}
                            src={process.env.PUBLIC_URL + "mapPin.png"}
                          />
                          {suggestion.description}
                        </div>
                      </li>
                    );
                  })}
                  {suggestionsx.slice(0, 3).map((val) => (
                    <li
                      className="autoCompleteElement d-flex"
                      style={{
                        width: "100%",
                        alignItems: "center",
                        columnGap: "1rem",
                      }}
                      onClick={() => {
                        onClickPacchettoHandler(val.slug);
                      }}
                    >
                      <img
                        width={"20%"}
                        src={
                          val.images[0]
                            ? val.images[0].img
                            : process.env.PUBLIC_URL + "placeholder.png"
                        }
                      />
                      {val.nome_circolo}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    );
  }
};
export default AdvancedTextSearch;
