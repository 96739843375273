import { React, useContext } from "react";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { Context } from "../../store/intl-context";
const UsefulLinks = () => {
  const intl = useIntl();
  const { language_cookie: language } = useContext(Context);
  const lng = language == 'en' ? '' : '/'+language;
  const menu = [
    { nome: "Green Fee", link: lng+"/campi-da-golf" },
    /*{ nome: "Gare", link: "/Gare" },*/
    { nome: "Vacanze Golf", link: lng+"/pacchetti" },
    { nome: "Contatti", link: lng+"/contatti" },
    { nome: "Accesso Circoli", link: "https://gestionale.bestime.golf?circoli" },
    { nome: "Accesso Travel", link: "https://gestionale.bestime.golf?travel" },
    { nome: "Termini e condizioni", link: "https://www.bestime.golf/doc_bg/2021-06-Condizioni-generali-prenotazione-acquisto-green-fee-e-gare.pdf" },
    { nome: "Privacy", link: "https://www.bestime.golf/doc_bg/2021-06-Informativa-estesa.pdf" }
  ];

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5 className="labelSmall"><FormattedMessage id="linkutili" /></h5>
        {menu.map((val) => (
          <a className="p-1" style={{ color: "white" }} href={val.link} >
            {intl.formatMessage({id:val.nome})}
          </a>
        ))}
        <a href="https://www.iubenda.com/privacy-policy/67383170" style={{ color: "white" }} target="_blank" className="p-1 iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy ">Cookie</a>
      </div>
    </>
  );
};
export default UsefulLinks;
