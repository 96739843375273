import { createPortal } from 'react-dom';
import classes from './Modal.css';
import ReactDom from 'react-dom';
const Backdrop = props=>{
    return<div className={classes.Backdrop}></div>
};
const ModalOverlay =props=>{
    return <div className="">
        <div className={classes.content}>{props.children}</div>
    </div>
}
const portalElement = document.getElementById('overlays');
const Modal =props=>{
return<>
{ReactDom.createPortal(<Backdrop></Backdrop>,portalElement)}
{ReactDom.createPortal(<ModalOverlay>{props.children}</ModalOverlay>,portalElement)}
</>

};

export default Modal;