import axios from "axios";
const PostGpdr = (accessToken, slug, docName, consents) => {
  console.log("MAGHEGGIO CONSENTS", consents);
  var data = JSON.stringify({
    access_token: `${accessToken}`,
    slug: `${slug}`,
    docName: `${docName}`,
    validity: 1092,
    consents: consents.map((consent) => ({
      name: consent.name,
      testo_consenso: consent.text,
      slug: consent.slug,
    })),
  });

  var config = {
    method: "post",
    url: process.env.REACT_APP_API_ENDPOINT+"/privacy/consents",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
};
export default PostGpdr;
