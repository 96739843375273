import React, { useState, useEffect } from "react";
import useHttp from "../../hooks/use-http";
import { useContext } from "react";
import CartContext from "./cart-context";
const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  name: "",
  session_token: "",
  login: (token, name, session_token, surname, email, tel) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {

  const cartCtx = useContext(CartContext);

  const { sendRequest: sendDataRequest, isLoading: isDataLoading } = useHttp();

  const initialToken = localStorage.getItem("token");
  const initialName = localStorage.getItem("name");
  const initialSurname = localStorage.getItem("surname");
  const initialMail = localStorage.getItem("email");
  const initialTel = localStorage.getItem("tel");
  const initialSession = localStorage.getItem("session_token");
  const [token, setToken] = useState(initialToken);
  const [name, setName] = useState(initialName);

  const [surname, setSurname] = useState(initialSurname);
  const [email, setEmail] = useState(initialMail);
  const [tel, setTel] = useState(initialTel);
  const [sessionToken, setSessionToken] = useState(initialSession);
  const userIsLoggedIn = !!token;

  const loginHandler = (token, name, session_token, surname, email, tel) => {
    console.log("LOGIN HANDLER FIRED", token, name, session_token, surname, email, tel);

    localStorage.setItem("session_token", session_token);

    setToken(token); 

    setName(name);

    setSessionToken(session_token);

    localStorage.setItem("token", token);

    localStorage.setItem("name", name);

    localStorage.setItem("surname", surname);
    if (email != null) {
      localStorage.setItem("email", email);
      localStorage.setItem("username", email);
    }
    console.log(email, 'EMAIL LOGIN');
    if (tel != null) {
    localStorage.setItem("tel", tel);
    }

    localStorage.setItem("session_token", session_token);
  };

  const otherDataHandler = ({ cognome, username, telefono }) => {
    console.log('OTHER DATA HANDLER');
    localStorage.setItem("surname", cognome);
    localStorage.setItem("email", username);
    localStorage.setItem("tel", telefono);

    setSurname(cognome);
    setEmail(username);
    setTel(telefono);
  };

  useEffect(() => {
    if (token){ 
      sendDataRequest({
        url: `${process.env.REACT_APP_API_ENDPOINT}/acl/user/${token}`,
        manageData: (e) => {
          otherDataHandler(e.data);
        },
      });
    }
  }, [token]);

  const logoutHandler = () => {
    setToken(null);
    localStorage.clear();
    cartCtx.cleanCart();
  };
  const contextValue = {
    token: token,

    name: name,
    surname: surname,
    email: email,
    tel: tel,

    session_token: sessionToken,

    isLoggedIn: userIsLoggedIn,

    login: loginHandler,

    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
