import React, { useState, useEffect } from "react";
import { useContext } from "react";
import SearchContext from "../store/search-context";
import ModalLogin from "../Login/ModalLogin";


import "./HeaderGrid.css";

import BackButton from "./BackButton";
import CalendarHeader from "./CalendarHeader";
import PeopleHeader from "./PeopleHeader";
import TermHeader from "./Term-header";
import AdvancedTextSearchGolf from "./SearchBar/AdvancedTextSearchGolf.jsx";
import { Link, NavLink } from "react-router-dom";
import { DatePicker } from "react-rainbow-components";
import useGetGolf from "../../hooks/use-get-golf";
import formatDate from "../function/format-date";
import dateWrapper from "../function/date-wrapper";
import AdvancedTextSearch from "./SearchBar/AdvancedTextSearch";
import Occupazione from "../../elements/Occupazione";
import useGetAllPacchetti from "../../hooks/get-all-pacchetti";
import { formatRoomQuery, formatRoomString } from "../function/format-rooms";
import WrappedLogin from "../WrappedLogin/WrappedLogin";

const HeaderGolf = (props) => {
  const srcCtx = useContext(SearchContext);

  const [isMenu, setIsMenu] = useState(false);
  const isMenuHandler = () => {
    setIsMenu(!isMenu);
  };

  const [date, setDate] = useState(new Date());
  const dateHandler = (data) => {
    setDate(data);
  };
  const [place, setPlace] = useState("");
  const PlaceHandler = (posto) => {
    setPlace(posto);
    console.log("POSTO", posto);
  };
  const campi = useGetGolf();
  useEffect(() => {
    console.log("I CAMPI DELL'HOOK", campi);
  }, [campi]);
  const [lonSearch, setLonSearch] = useState();
  const liftLon = (lon) => {
    console.log("LON SEARCH", lon);
    setLonSearch(lon);
  };
  const [latSearch, setLatSearch] = useState();
  const liftLat = (lat) => {
    console.log("LAT SEARCH", lat);
    setLatSearch(lat);
  };
  const pacchetti = useGetAllPacchetti();
  const [roomString, setRoomString] = useState("");
  const [isOccupation, setIsOccupation] = useState(false);
  const isOccupationHandler = () => {
    setIsOccupation(!isOccupation);
  };
  const [roomQuery, setRoomQuery] = useState("");
  const [stanze, setStanze] = useState([
    { adulti: 2, bambini: 0, eta: [], datiCamera: { prezzo: 0 } },
  ]);
  const liftStanze = (stanze) => {
    setStanze([...stanze]);
    console.log("LIFT STANZE HEADER", [...stanze]);
    setRoomString(formatRoomString([...stanze]));
    setRoomQuery(formatRoomQuery([...stanze]));
    setIsOccupation(false);
  };
  const submit = () => {
    console.log("SUBMITTO", latSearch, lonSearch, place, date);
    srcCtx.sendLat(latSearch);
    srcCtx.sendLon(lonSearch);
    srcCtx.send(place);
    srcCtx.sendData(formatDate(date));
    if (props.idCanale === 8 && roomQuery != "") {
      srcCtx.sendRoomsQuery(roomQuery);
    }
    isMenuHandler();
  };
  return (
    <>
      <div className="headerGrid d-flex align-items-center justify-content-center">
        <div style={{ cursor: "pointer" }} className="container px-0">
          <div className="row header-row d-flex align-items-center">
            <div className="col-5 col-md-3 px-0 d-flex align-items-center">
              <div className="logo-header">
                <Link to={{ pathname: "/" }}>
                  <img
                    src={process.env.PUBLIC_URL + "../logo-bgi.png"}
                    alt=""
                    className="main-logo"
                  />
                </Link>
              </div>
              <div>
                <BackButton />
              </div>






              <div className="d-none d-md-block btn-best-time">
                <Link to={{pathname: "/time"}} className="text-white">BesTime</Link>
              </div>
              <div className="d-block d-md-none btn-best-time">
                <Link to={{pathname: "/time"}} className="text-white">BT</Link>
              </div>





            </div>
            <div className="col-3 col-md-6 mt-1">
              <div className="internalGrid">
                <TermHeader idCanale={props.idCanale} />
                <div>
                  <CalendarHeader />
                </div>
                <div>
                  <PeopleHeader idCanale={props.idCanale} />
                </div>
              </div>
            </div>
            <div className="col-3 col-md-3   ">
              <div className="box box3">
		        <WrappedLogin/>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMenu ? (
        <div className="d-flex justify-content-center p-3 align-items-center">
          <div className="d-flex flex-column pt-">
            <div className="col-12 pr-0">
              {props.idCanale === 3 && (
                <AdvancedTextSearchGolf
                  header={true}
                  liftLon={liftLon}
                  liftLat={liftLat}
                  onPick={PlaceHandler}
                  toSearch={campi}
                />
              )}
              {props.idCanale === 8 && (
                <AdvancedTextSearch
                  header={true}
                  liftLon={liftLon}
                  liftLat={liftLat}
                  onPick={PlaceHandler}
                  toSearch={pacchetti}
                />
              )}
            </div>
            <div className="col-12 d-flex mt-3" style={{ columnGap: "1rem" }}>
              {props.idCanale === 3 && (
                <div className="pl-0 pr-0 col-6">
                  <input type="number" />
                </div>
              )}
              {props.idCanale === 8 && (
                <div className="d-flex flex-column">
                  <div
                    style={{ height: "50px" }}
                    onClick={() => {
                      isOccupationHandler();
                    }}
                    className="form-control d-flex  align-items-center"
                  >
                    {roomString != "" ? roomString : "Scegli la sistemazione"}
                  </div>
                  {isOccupation && (
                    <Occupazione stanze={stanze} mode={2} submit={liftStanze} />
                  )}
                </div>
              )}
              <div className="pl-0 pr-0 col-6">
                <DatePicker value={date} onChange={dateHandler} />
              </div>
            </div>

            <button onClick={submit} className="btn btn-primary mt-3">
              Conferma
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default HeaderGolf;
