import React, { useState, useContext } from "react";
import LoginForm from "./LoginForm";
import axios from "axios";
import AuthContext from "../store/auth-context";
import Modal from "./Modal";
import PostGpdr from "../function/post-gdpr";
import SignupForm from "./RegisterForm";
import { useIntl, FormattedMessage } from "react-intl";

const ModalLogin = (props) => {
  const intl = useIntl();
  const [errorText, setErrorText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const showModalHandler = () => {
    setShowModal(!showModal);
  };

  const autchCtx = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const signupSetter = () => {
    setIsLogin(false);
    setErrorText('');
  };
  const loginSetter = () => {
    setIsLogin(true);
    setErrorText('');
  };

  const registerUser = (event) => {
    var data = JSON.stringify({
      nome: event.nome,
      cognome: event.cognome,
      email: event.email,
      pwd: event.password,
      code: event.code,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT+"/acl/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // autchCtx.login(response.data.data.access_token);
        // console.log(response.data);
        // history.replace("/");
      })
      .catch(function (error) {
        console.log(error);
        console.log(config);
      });
  };
  const sendRegisterUser = (event) => {
    console.log("FORM DI REGISTRAZIONE", event);
    
    console.log(
      event.password,
      event.nome,
      event.cognome,
      event.email,
      "dati registrazione"
    );
    var data = JSON.stringify({
      username: event.email,
      pwd: event.password,
      nome: event.nome,
      cognome: event.cognome,
    });
    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT+"/acl/signup",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        autchCtx.login(
          response.data.data.access_token,
          event.nome,
          response.data.data.session_token,
          event.cognome,
          event.email,
          response.data.data.telefono
        );
        console.log(response.data.data.nome, "NOME PASSATO");
        setShowModal(false);
        if (props.updateData) {
          props.updateData(response.data.data.nome,response.data.data.cognome,
            response.data.data.username,
            response.data.data.telefono);
        }
        console.log(response.data, "dopo registrazione");
        PostGpdr(
          response.data.data.access_token,
          "iscrizione",
          "iscrizione",
          [...event.consents]
        );

        setErrorText('');

        // history.replace("/");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          setErrorText(`Errore nella registrazione: ${error.response.data.errore}`);
        }
      });
  };

  const sendUser = (event) => {
    console.log(event);
    setErrorText('');
    console.log(event.password);
    var data = JSON.stringify({
      username: event.username,
      pwd: event.password,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT+"/acl/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        autchCtx.login(
          response.data.data.access_token,
          response.data.data.nome,
          response.data.data.session_token,
          response.data.data.cognome,
          response.data.data.username,
          response.data.data.telefono
        );
        setShowModal(false);
          console.log('dopo login');
          if (props.updateData) {
            props.updateData(response.data.data.nome,response.data.data.cognome,
              response.data.data.username,
              response.data.data.telefono);
          }
        // history.replace("/");
      })
      .catch(function (error) {
        setErrorText(error?.response?.data?.errore ? error.response.data.errore : intl.formatMessage({id:'generic-error'}));
        console.log(error.response.data, "RESPONSE DATA");
        console.log(config);
      });
  };

  if (props.mode === 1) {
    return (
      <>
        <span
          onClick={() => showModalHandler()}
          className={`btn ${props.classcustom}`}
        >

          <span className="span d-none d-md-block">
             {props.testocustom} <i className="fa fa-sign-in icon-login"></i>
          </span>
           <span className="span d-block d-md-none">
               <i className="fa fa-sign-in icon-login"></i>
          </span>

        </span>
        {showModal && (
          <Modal>
            <div class="show-overlaylogin">
              <div class="">
                <div class="">
                  <div class=" ">
                    <div class="text-right">
                      <div
                        onClick={() => showModalHandler()}
                        className="modalloginchiudi"
                      >
                        X
                      </div>
                      {/* <a href="index.html" class="d-block auth-logo">
                      <img
                        src={process.env.PUBLIC_URL + "logo1.png"}
                        alt=""
                        height="22"
                        class="logo logo-light"
                      />
                    </a> */}
                    </div>
                  </div>
                </div>
                <div class="row align-items-center justify-content-center">
                  <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card">
                      <div class="card-body p-4 ">
                        <div class="text-center mt-2">
                          <h5 style={{ color: "#a91354!important" }}>
                            <FormattedMessage id="Bentornato!" />
                          </h5>
                          <p className="text-muted">
                          <FormattedMessage id="Effettua l'accesso per continuare" />
                          </p>
                          <div className="row">
                            <div
                              onClick={loginSetter}
                              className={ `loginregbutton col-6${ !isLogin ? '' : ' active'}` }  
                            >
                              Login
                            </div>
                            <div
                              onClick={signupSetter}
                              className={ `loginregbutton col-6${ !isLogin ? ' active' : ''}` }
                            >
                              {intl.formatMessage({id:"Registrati"})}
                            </div>
                          </div>
                        </div>
                        <div class="p-2 mt-4">
                          
                          {isLogin == true ? (
                            <LoginForm
                              close={props.onClickModal}
                              onClickModal={props.onClickModal}
                              sendUser={sendUser}
                              email={props.email ? props.email : ''}
                            ></LoginForm>
                          ) : (
                            <SignupForm
                              sendRegisterUser={sendRegisterUser}
                              nome={props.nome ? props.nome : ''}
                              cognome={props.cognome ? props.cognome : ''}
                              email={props.email ? props.email : ''}
                            ></SignupForm>
                          )}
                        </div>
                        <p className="text-danger text-center">{errorText != '' ? intl.formatMessage({id:errorText}) : ''}</p>
                      </div>
                    </div>

                    <p className="copyright-string text-center">
                      &copy; 2022 Developed by Eurolink S.r.l.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  } else if (props.mode === 2) {
    return (
      <div class="row align-items-center justify-content-center">
        <div class="col-12">
          <div class="card">
            <div class="card-body p-4 ">
              <div class="text-center mt-2">
              <h5 style={{ color: "#a91354!important" }}>
                            <FormattedMessage id="Bentornato!" />
                          </h5>
                          <p className="text-muted">
                          <FormattedMessage id="Effettua l'accesso per continuare" /></p>
                <div className="row">
                  <div
                    onClick={loginSetter}
                    className="loginregbutton col-6"
                    style={{ cursor: "pointer" }}
                  >
                    Login
                  </div>
                  <div
                    onClick={signupSetter}
                    className="loginregbutton col-6"
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#27287a",
                    }}
                  >
                    {intl.formatMessage({id:'Registrati'})}
                  </div>
                </div>
              </div>
              <div class="p-2 mt-4">
                {isLogin == true ? (
                  <LoginForm
                    close={props.onClickModal}
                    onClickModal={props.onClickModal}
                    sendUser={sendUser}
                    email={props.email ? props.email : ''}
                    cognome={props.cognome ? props.cognome : ''}
                    nome={props.nome ? props.nome : ''}
                  ></LoginForm>
                ) : (
                  <SignupForm sendRegisterUser={sendRegisterUser} email={props.email ? props.email : ''}
                  cognome={props.cognome ? props.cognome : ''}
                  nome={props.nome ? props.nome : ''}></SignupForm>
                )}
              </div>
            </div>
          </div>

          <p className="copyright-string text-center">
            &copy; 2022 Developed by Eurolink S.r.l.
          </p>
        </div>
      </div>
    );
  }
};
export default ModalLogin;
