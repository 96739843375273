import { React, useContext, useEffect } from "react";
import "./footer.css";

import LogoPart from "./FooterComponents/LogoPart";
import UsefulLinks from "./FooterComponents/UsefulLinks";
import FollowUs from "./FooterComponents/FollowUs";
import FormFooter from "./FooterComponents/FormFooter";
import Payments from "./FooterComponents/Payments";
import TimeContext from "../store/time-context";
import { useIntl, FormattedMessage } from "react-intl";
import Partners from "./FooterComponents/partners/Partners";

import { Context } from "../store/intl-context";

const Footer = (props) => {
  const intl = useIntl();
  const timeCtx = useContext(TimeContext);
  return (
    <>
      {timeCtx.widget ? (
        ""
      ) : (
        <>
          <div id="foot" className="bgFooter " style={{ color: "white" }}>
            <div className="footerGrid">
              <div>
                <LogoPart />
              </div>
              <div>
              <div className="" style={{ lineHeight: "1.3", fontSize: "0.8em" }}>
          
          <b>Eurolink S.r.l.</b><br />
          FROSINONE <br /><i>sede legale e operativa</i><br />
                P.zza Sandro Pertini, snc - Frosinone

                <br /><br />
                ROMA <br /><i>sede operativa</i><br />
                Via Riccardo Gigante, 4

                <br /><br />
                <i>sede operativa</i><br />
V.le Luigi Schiavonetti, 290/b
        <br /><br />
          {intl.formatMessage({id:'I pacchetti golf sono gestiti da:'})}
          <br />
          <b>Business Class Viaggi</b>
          <br />
          {intl.formatMessage({id:'Autorizzazione regione'})}
          <br />
          4046 - 26/06/2008

        </div>
              </div>
              <div>
                  <UsefulLinks />
              </div>
              <div>
                  <FollowUs />
              </div>
              <div>
                <Payments />
              </div>
            </div>
            <div className="copyright ">
              <h4 className="text-white" style={{ margin: 0 }}>
              © 2022 Eurolink S.r.l. - P.I. 01973270604 - All rights reserved
              </h4>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Footer;
