import React, { useState, useEffect,useContext } from "react";
import AuthContext from "../components/store/auth-context";
const useGetAllPacchetti = () => {
const authCtx=useContext(AuthContext)
  const [pacchettix, setPacchettix] = useState([]);
  useEffect(() => {
    var axios = require("axios");
    var FormData = require("form-data");
    var data = new FormData();
    data.append("id_tipologia_pacchetto", "");
    data.append("prezzo_min", "");
    data.append("prezzo_max", "");
    data.append("ssid_utente_creazione", "");
    data.append("ssid_utente_modifica", "");
    data.append("data_fine", "");
    data.append("regione", "");
    data.append("itemsperpage", "0");

    var config = {
      method: "post",
      url: process.env.REACT_APP_API_ENDPOINT+"/turismoadmin/searchpacchetti",
      headers: {
			  'accept':'application/json',
				  'Authorization':"Bearer "+authCtx.session_token
	  },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log("RESPONSE HOOK ALL PACCHETTI",response.data.data);
			  setPacchettix(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
		return pacchettix
};
export default useGetAllPacchetti;
