import React, { useState, useEffect } from "react";
import formatDate from "../../function/format-date";
import "./SearchGolf.css";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import SearchContext from "../../store/search-context";
import useGetGolf from "../../../hooks/use-get-golf";
import useGetAllGare from "../../../hooks/get-all-gare";
import { DatePicker } from "react-rainbow-components";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useHistory } from "react-router";
import { addDays } from "date-fns";
const SearchGare = (props) => {
  const gare = useGetAllGare();
  useEffect(() => {
    console.log("GAREX", gare);
  }, [gare]);
  const srcCtx = useContext(SearchContext);
  const history = useHistory();

  const today = new Date();
  const oneWeek = addDays(today, 7);
  const formattedOneWeek = formatDate(oneWeek, "YYYY-mm-dd");
  const formattedToday = formatDate(today, "YYYY-mm-dd");
  const [date, setDate] = useState([today, oneWeek]);

  const [term, setTerm] = useState("");
  const [suggestionsx, setSuggestionsx] = useState([]);

  const handleSelect = (e) => {
    console.log("EVENTO HANDLE", e);
    setTerm(e);
    setSuggestionsx([]);
  };
  const submitPlaceCallBack = async (event) => {
    event.preventDefault();
    const results = await geocodeByAddress(term);

    srcCtx.send(term);

    const options = { year: "numeric", month: "numeric", day: "numeric" };

    srcCtx.sendNumbers(1);
    if (date) {
      srcCtx.sendData(formatDate(date[0], "YYYY-mm-dd"));
    } else {
      srcCtx.sendData("");
    }

    const latLng = await getLatLng(results[0]);
    srcCtx.sendLat(latLng.lat);
    srcCtx.sendLon(latLng.lng);
    console.log("LAT E LONG", latLng);
    // history.replace({ pathname: "/pacchetti", state: latLng });
    //
    history.push({
      pathname: "/gare",
      state: {
        lat: latLng.lat,
        lon: latLng.lng,
        datain: formatDate(date[0], "YYYY-mm-dd"),
        dataout: formatDate(date[1], "YYYY-mm-dd"),
      },
    });
  };
  const onChangeHandler = (text) => {
    let matches = [];
    if (text != "") {
      matches = gare.filter((golf) => {
        if (golf.nome.toLowerCase().includes(text.toLowerCase())) {
          return golf;
        }
      });
    }
    console.log("TOSEARCH", matches);
    setSuggestionsx(matches);
    console.log("TEXT", text);
    setTerm(text);
    setHide(false);
  };
  const dateHandler = (d) => {
    console.log("D RANGE", d);
    setDate([...d]);
  };
  const onClickPacchettoHandler = (slug) => {
    history.push(`/gara/${slug}`);
  };
  const [hide, setHide] = useState(true);
  return (
    <>
      <form style={{ width: "75%" }} onSubmit={submitPlaceCallBack}>
        <div style={{ rowGap: "1rem" }} className="d-flex  flex-column">
          <div className="golf-grid">
            <div className="">
              <PlacesAutocomplete
                value={term}
                ref={(a) => {
                  if (!a) return;
                  a.handleInputOnBlur = () => {
                    console.log("Sostitusicimi con set hide true");
                  };
                }}
                onChange={(e) => onChangeHandler(e)}
                onSelect={handleSelect}
                searchOptions={{
                  types: ["(regions)"],
                  componentRestrictions: { country: "IT" },
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className=" ">
                    <div className="d-flex justify-content-center align-items-center">
                      <input
                        onClick={() => {
                          setHide(false);
                        }}
                        required={true}
                        
                        style={{
                          border: "none",
                        }}
                        className="form-control"
                        defaultValue={props.defVal}
                        {...getInputProps({
                          placeholder: "Scegli la zona o gara",
                        })}
                      ></input>
                    </div>
                    {loading ? <div>...loading</div> : null}

                    <div
                      onBlur={() => {
                        console.log("CLICCO FUORI");
                      }}
                      style={{ position: "absolute", zIndex: 999 }}
                      className="d-flex dinWidth justify-content-center align-items-center"
                    >
                      <ul
                        style={{ margin: 0 }}
                        className="autoCompItemDisplayer-map"
                      >
                        {!hide &&
                          suggestions.slice(0, 3).map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "blue"
                                : "white",
                            };
                            const className = "autoCompleteElement";
                            console.log("suggestions", suggestion);
                            return (
                              <li
                                onClick={() => {
                                  submitPlaceCallBack(suggestion.description);
                                }}
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                  className,
                                })}
                              >
                                <div className="">
                                  <img
                                    width={20}
                                    src={process.env.PUBLIC_URL + "mapPin.png"}
                                  />
                                  {suggestion.description}
                                </div>
                              </li>
                            );
                          })}
                        {!hide &&
                          suggestionsx.slice(0, 3).map((val) => (
                            <li
                              className="autoCompleteElement d-flex"
                              style={{
                                width: "100%",
                                alignItems: "center",
                                columnGap: "1rem",
                              }}
                              onClick={() => {
                                onClickPacchettoHandler(val.uuid);
                              }}
                            >
                              <img
                                width={"20%"}
                                src={
                                  val.images
                                    ? val.images[0].img
                                    : process.env.PUBLIC_URL + "placeholder.png"
                                }
                              />
                              {val.nome}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div style={{ color: "black" }}>
		  <div style={{zIndex:100}} className="labelx"><FormattedMessage id="fromto" /></div>
              <DatePicker
                minDate={new Date()}
                selectionType="range"
		  label="ciao"
                required
                onChange={(e) => {
                  dateHandler(e);
                }}
                type="date"
                value={date}
              />
            </div>
          </div>
          <div className="col-12 p-0 d-flex justify-content-center align-items-start">
            <button className="btn btn-primary btn-sm-block" type="submit">
              <FormattedMessage id="Proceed" />
            </button>{" "}
          </div>
        </div>
      </form>
    </>
  );
};
export default SearchGare;
