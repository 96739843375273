import {useEffect, useState} from 'react'



const dateWrapper = (date) => {
    if (typeof date === 'string') {
        date = date.replace(" ", "T");
    } else if (typeof date === 'undefined') {
        return new Date();
    }
   
    let dt = new Date(date);
    if (dt != 'Invalid date') {
        return dt;
    } else {
        return new Date();
    }

}


export default dateWrapper